import { ConfirmModal, showToast } from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import {
  getGetIncidentsIdAnnotationsQueryKey,
  useDeleteAnnotationsId,
} from "../../../../.rest-hooks/annotations";
import type { Incident } from "../../../../.rest-hooks/types";
import { STATE_VIEW_ANNOTATIONS_UUID } from "../../incidents/StateView/constants";

interface GetDeleteAnnotationModalCopyParams {
  annotationType?: "annotation" | "flagged data annotation" | "uploaded media";
  incident: Incident | undefined;
  incidentId: string;
}

export const getDeleteAnnotationModalCopy = ({
  annotationType = "annotation",
  incident,
  incidentId,
}: GetDeleteAnnotationModalCopyParams) => {
  if (incidentId === STATE_VIEW_ANNOTATIONS_UUID) {
    return `Are you sure you want to remove this global ${annotationType}? This action cannot be undone.`;
  }

  if (!incident) {
    return `Are you sure you want to remove the ${annotationType} from this incident? This action cannot be undone.`;
  }

  return `Are you sure you want to remove the ${annotationType} from ${incident.attributes.name}? This action cannot be undone.`;
};

interface DeleteAnnotationModalProps {
  annotationId: string;
  incident: Incident | undefined;
  incidentId: string;
  isOpen: boolean;
  onCancel: () => void;
  onDeleteSuccess: () => void;
}

const DeleteAnnotationModal = ({
  annotationId,
  incidentId,
  incident,
  isOpen,
  onCancel,
  onDeleteSuccess,
}: DeleteAnnotationModalProps) => {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteAnnotationMutation } = useDeleteAnnotationsId({
    mutation: {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: getGetIncidentsIdAnnotationsQueryKey(incidentId),
        });

        showToast({
          variant: "success",
          title: "Annotation deleted",
        });
      },
      onError: () => {
        showToast({
          variant: "error",
          title: "Unable to delete annotation",
        });
      },
    },
  });

  const onConfirm = () => {
    return deleteAnnotationMutation({
      id: annotationId,
    });
  };

  return (
    <ConfirmModal
      confirmLabel="Delete"
      title="Delete markup?"
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      onConfirmSuccess={onDeleteSuccess}
    >
      {getDeleteAnnotationModalCopy({
        incident,
        incidentId,
      })}
    </ConfirmModal>
  );
};

export default DeleteAnnotationModal;
