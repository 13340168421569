/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  DeleteAnnotationsId200,
  GetAnnotationsId200,
  GetIncidentsIdAnnotations200,
  GetIncidentsIdAnnotationsParams,
  PatchAnnotationsId200,
  PatchAnnotationsIdBody,
  PostIncidentsIdAnnotations201,
  PostIncidentsIdAnnotationsBody,
} from "./types";
import type { JSONAPIErrorResponse } from "./types/jsonapi.yml";

/**
 * @summary Get an annotation
 */
export const getAnnotationsId = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetAnnotationsId200>> => {
  return axios.get(`/annotations/${id}`, options);
};

export const getGetAnnotationsIdQueryKey = (id: string) => {
  return [`/annotations/${id}`] as const;
};

export const getGetAnnotationsIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getAnnotationsId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnnotationsId>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAnnotationsIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAnnotationsId>>
  > = ({ signal }) => getAnnotationsId(id, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAnnotationsId>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetAnnotationsIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAnnotationsId>>
>;
export type GetAnnotationsIdQueryError = AxiosError<JSONAPIErrorResponse>;

export function useGetAnnotationsId<
  TData = Awaited<ReturnType<typeof getAnnotationsId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnnotationsId>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAnnotationsId>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetAnnotationsId<
  TData = Awaited<ReturnType<typeof getAnnotationsId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnnotationsId>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAnnotationsId>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAnnotationsId<
  TData = Awaited<ReturnType<typeof getAnnotationsId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnnotationsId>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get an annotation
 */

export function useGetAnnotationsId<
  TData = Awaited<ReturnType<typeof getAnnotationsId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getAnnotationsId>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetAnnotationsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update an annotation
 */
export const patchAnnotationsId = (
  id: string,
  patchAnnotationsIdBody: PatchAnnotationsIdBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PatchAnnotationsId200>> => {
  return axios.patch(`/annotations/${id}`, patchAnnotationsIdBody, options);
};

export const getPatchAnnotationsIdMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAnnotationsId>>,
    TError,
    { id: string; data: PatchAnnotationsIdBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchAnnotationsId>>,
  TError,
  { id: string; data: PatchAnnotationsIdBody },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchAnnotationsId>>,
    { id: string; data: PatchAnnotationsIdBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return patchAnnotationsId(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchAnnotationsIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchAnnotationsId>>
>;
export type PatchAnnotationsIdMutationBody = PatchAnnotationsIdBody;
export type PatchAnnotationsIdMutationError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Update an annotation
 */
export const usePatchAnnotationsId = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAnnotationsId>>,
    TError,
    { id: string; data: PatchAnnotationsIdBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchAnnotationsId>>,
  TError,
  { id: string; data: PatchAnnotationsIdBody },
  TContext
> => {
  const mutationOptions = getPatchAnnotationsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete an annotation
 */
export const deleteAnnotationsId = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DeleteAnnotationsId200>> => {
  return axios.delete(`/annotations/${id}`, options);
};

export const getDeleteAnnotationsIdMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAnnotationsId>>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAnnotationsId>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAnnotationsId>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteAnnotationsId(id, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAnnotationsIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAnnotationsId>>
>;

export type DeleteAnnotationsIdMutationError = AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Delete an annotation
 */
export const useDeleteAnnotationsId = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAnnotationsId>>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteAnnotationsId>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteAnnotationsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Pagination is optional.
 * @summary List annotations for an incident
 */
export const getIncidentsIdAnnotations = (
  id: string,
  params?: GetIncidentsIdAnnotationsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetIncidentsIdAnnotations200>> => {
  return axios.get(`/incidents/${id}/annotations`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetIncidentsIdAnnotationsQueryKey = (
  id: string,
  params?: GetIncidentsIdAnnotationsParams,
) => {
  return [`/incidents/${id}/annotations`, ...(params ? [params] : [])] as const;
};

export const getGetIncidentsIdAnnotationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getIncidentsIdAnnotations>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params?: GetIncidentsIdAnnotationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdAnnotations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetIncidentsIdAnnotationsQueryKey(id, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIncidentsIdAnnotations>>
  > = ({ signal }) =>
    getIncidentsIdAnnotations(id, params, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    refetchInterval: 300000,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getIncidentsIdAnnotations>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetIncidentsIdAnnotationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIncidentsIdAnnotations>>
>;
export type GetIncidentsIdAnnotationsQueryError =
  AxiosError<JSONAPIErrorResponse>;

export function useGetIncidentsIdAnnotations<
  TData = Awaited<ReturnType<typeof getIncidentsIdAnnotations>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params: undefined | GetIncidentsIdAnnotationsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdAnnotations>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getIncidentsIdAnnotations>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetIncidentsIdAnnotations<
  TData = Awaited<ReturnType<typeof getIncidentsIdAnnotations>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params?: GetIncidentsIdAnnotationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdAnnotations>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getIncidentsIdAnnotations>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetIncidentsIdAnnotations<
  TData = Awaited<ReturnType<typeof getIncidentsIdAnnotations>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params?: GetIncidentsIdAnnotationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdAnnotations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary List annotations for an incident
 */

export function useGetIncidentsIdAnnotations<
  TData = Awaited<ReturnType<typeof getIncidentsIdAnnotations>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params?: GetIncidentsIdAnnotationsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdAnnotations>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetIncidentsIdAnnotationsQueryOptions(
    id,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create an annotation
 */
export const postIncidentsIdAnnotations = (
  id: string,
  postIncidentsIdAnnotationsBody: PostIncidentsIdAnnotationsBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostIncidentsIdAnnotations201>> => {
  return axios.post(
    `/incidents/${id}/annotations`,
    postIncidentsIdAnnotationsBody,
    options,
  );
};

export const getPostIncidentsIdAnnotationsMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postIncidentsIdAnnotations>>,
    TError,
    { id: string; data: PostIncidentsIdAnnotationsBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postIncidentsIdAnnotations>>,
  TError,
  { id: string; data: PostIncidentsIdAnnotationsBody },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postIncidentsIdAnnotations>>,
    { id: string; data: PostIncidentsIdAnnotationsBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return postIncidentsIdAnnotations(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostIncidentsIdAnnotationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postIncidentsIdAnnotations>>
>;
export type PostIncidentsIdAnnotationsMutationBody =
  PostIncidentsIdAnnotationsBody;
export type PostIncidentsIdAnnotationsMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Create an annotation
 */
export const usePostIncidentsIdAnnotations = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postIncidentsIdAnnotations>>,
    TError,
    { id: string; data: PostIncidentsIdAnnotationsBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postIncidentsIdAnnotations>>,
  TError,
  { id: string; data: PostIncidentsIdAnnotationsBody },
  TContext
> => {
  const mutationOptions = getPostIncidentsIdAnnotationsMutationOptions(options);

  return useMutation(mutationOptions);
};
