import { useEffect } from "react";
import useMapContext from "../../Map/useMapContext";
import { isGeoJsonSource } from "../../types";
import { loadImage } from "../../utils/loadImage";
import { UPLOADED_MEDIA_SOURCE_ID } from "./constants";
import type {
  MediaAnnotation,
  MediaAnnotationFeature,
  MediaAnnotationFeatureCollection,
} from "./types";

interface UseUploadedMediaDataParams {
  annotations: MediaAnnotation[];
}

const useUploadedMediaMapData = ({
  annotations,
}: UseUploadedMediaDataParams) => {
  const map = useMapContext();

  useEffect(() => {
    const controller = new AbortController();

    const loadIconsAndSetData = async () => {
      await loadImage({
        imageId: "uploadedMedia",
        map,
        pixelRatio: 4,
        src: "/icons/upload-media.png",
      });

      if (controller.signal.aborted) return;

      const source = map.getSource(UPLOADED_MEDIA_SOURCE_ID);
      if (!isGeoJsonSource(source)) {
        return;
      }

      const features = annotations.map<MediaAnnotationFeature>(
        (uploadMedia) => ({
          type: "Feature",
          properties: {
            annotationId: uploadMedia.id,
            mediaUrl: uploadMedia.attributes.media.fileUri,
            location: uploadMedia.attributes.location ?? "",
            note: uploadMedia.attributes.note,
            type: uploadMedia.attributes.annotationType,
          },
          geometry: uploadMedia.attributes.geometry,
        }),
      );

      const featureCollection: MediaAnnotationFeatureCollection = {
        type: "FeatureCollection",
        features,
      };

      source.setData(featureCollection);
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadIconsAndSetData();

    return () => controller.abort();
  }, [annotations, map]);
};

export default useUploadedMediaMapData;
