import getConfig from "next/config";
import {
  avlDataLayer,
  type avlVraOption,
  type avlAppliancesOption,
  type avlPortableOption,
} from "../../../config/layers/layers";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import type { SubLayerState } from "../../util/ActiveLayersProvider/useActiveLayers";
import AVLPositionsLayer from "./AVLPositionsLayer";
import {
  AVL_DATA_OPTION_APPLIANCES_QUERY_URL,
  AVL_DATA_OPTION_APPLIANCES_SYMBOLOGY_URL,
  AVL_DATA_OPTION_PORTABLE_QUERY_URL,
  AVL_DATA_OPTION_PORTABLE_SYMBOLOGY_URL,
  AVL_DATA_QUERY_URL,
  AVL_DATA_SYMBOLOGY_URL,
  AVL_PORTABLE_SYMBOLOGY_OVERRIDES,
  AVL_POSITIONS_LAYER_ID,
  AVL_POSITIONS_OPTION_APPLIANCES_LAYER_ID,
  AVL_POSITIONS_OPTION_APPLIANCES_SOURCE_ID,
  AVL_POSITIONS_OPTION_PORTABLE_LAYER_ID,
  AVL_POSITIONS_OPTION_PORTABLE_SOURCE_ID,
  AVL_POSITIONS_OPTION_VRA_LAYER_ID,
  AVL_POSITIONS_OPTION_VRA_SOURCE_ID,
  AVL_POSITIONS_SOURCE_ID,
  QUERY_OPTIONS_COMMON,
  QUERY_OPTIONS_LEGACY,
} from "./constants";

const {
  publicRuntimeConfig: { COP_ENABLED },
} = getConfig();

// AVL options are conditional depending on a feature flag, which requires us
// to do some generic hardcoding here instead of using the `LayerConfigState`
// generic.
export type AVLPositionsLayerState = SubLayerState<
  typeof avlAppliancesOption | typeof avlPortableOption | typeof avlVraOption
> & {
  showLabels: boolean;
};

interface AVLPositionsProps {
  isCopView: boolean;
}

const AVLPositions = ({ isCopView }: AVLPositionsProps) => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();
  const authAccessToken = useAuthAccessToken();

  if (!isLayerActive(avlDataLayer.id) || !authAccessToken) {
    return null;
  }

  const state = getLayerState<AVLPositionsLayerState>(avlDataLayer.id);

  // Once COP is enabled, this code can be deleted
  if (!COP_ENABLED) {
    return (
      <AVLPositionsLayer
        accessToken={authAccessToken}
        // Note: This legacy layer contains both appliance and portable types which does not work properly with the
        // AVLPopup component, however, this is a known issue and will be resolved when COP is enabled.
        avlType="appliance"
        isCopView={isCopView}
        layerId={AVL_POSITIONS_LAYER_ID}
        opacity={state?.opacity}
        queryOptions={QUERY_OPTIONS_LEGACY}
        queryUrl={AVL_DATA_QUERY_URL}
        showLabels={state?.showLabels}
        sourceId={AVL_POSITIONS_SOURCE_ID}
        symbologyUrl={AVL_DATA_SYMBOLOGY_URL}
      />
    );
  }

  const appliancesEnabled = !!state?.avlAppliancesOption?.isActive;
  const portableEnabled = !!state?.avlPortableOption?.isActive;
  const vraEnabled = !!state?.avlVraOption?.isActive;

  return (
    <>
      {appliancesEnabled && (
        <AVLPositionsLayer
          accessToken={authAccessToken}
          avlType="appliance"
          isCopView={isCopView}
          layerId={AVL_POSITIONS_OPTION_APPLIANCES_LAYER_ID}
          opacity={state?.opacity}
          queryOptions={{
            ...QUERY_OPTIONS_COMMON,
            queryParams: {
              ...QUERY_OPTIONS_COMMON.queryParams,
              // We have to filter out all of the known external agencies for the RFS appliances to show properly
              where: ["VRA"]
                .map((category) => `Category!='${category}'`)
                .join("&&"),
            },
          }}
          queryUrl={AVL_DATA_OPTION_APPLIANCES_QUERY_URL}
          showLabels={state?.showLabels}
          sourceId={AVL_POSITIONS_OPTION_APPLIANCES_SOURCE_ID}
          // This is required as the appliances layer also includes symbology for portable radios, and if switched on
          // prior to portable radios, the layer's own symbology would be loaded first and not allow Athena to override
          symbologyOverrides={AVL_PORTABLE_SYMBOLOGY_OVERRIDES}
          symbologyUrl={AVL_DATA_OPTION_APPLIANCES_SYMBOLOGY_URL}
        />
      )}
      {portableEnabled && (
        <AVLPositionsLayer
          accessToken={authAccessToken}
          avlType="portable"
          isCopView={isCopView}
          layerId={AVL_POSITIONS_OPTION_PORTABLE_LAYER_ID}
          opacity={state?.opacity}
          queryOptions={QUERY_OPTIONS_COMMON}
          queryUrl={AVL_DATA_OPTION_PORTABLE_QUERY_URL}
          showLabels={state?.showLabels}
          sourceId={AVL_POSITIONS_OPTION_PORTABLE_SOURCE_ID}
          symbologyOverrides={AVL_PORTABLE_SYMBOLOGY_OVERRIDES}
          symbologyUrl={AVL_DATA_OPTION_PORTABLE_SYMBOLOGY_URL}
        />
      )}
      {vraEnabled && (
        <AVLPositionsLayer
          accessToken={authAccessToken}
          avlType="appliance"
          isCopView={isCopView}
          layerId={AVL_POSITIONS_OPTION_VRA_LAYER_ID}
          opacity={state?.opacity}
          queryOptions={{
            ...QUERY_OPTIONS_COMMON,
            queryParams: {
              ...QUERY_OPTIONS_COMMON.queryParams,
              where: "Category='VRA'",
            },
          }}
          queryUrl={AVL_DATA_OPTION_APPLIANCES_QUERY_URL}
          showLabels={state?.showLabels}
          sourceId={AVL_POSITIONS_OPTION_VRA_SOURCE_ID}
          // This is required as the appliances layer also includes symbology for portable radios, and if switched on
          // prior to portable radios, the layer's own symbology would be loaded first and not allow Athena to override
          symbologyOverrides={AVL_PORTABLE_SYMBOLOGY_OVERRIDES}
          symbologyUrl={AVL_DATA_OPTION_APPLIANCES_SYMBOLOGY_URL}
        />
      )}
    </>
  );
};

export default AVLPositions;
