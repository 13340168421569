import AnnotatedSymbolHoverPopup from "../../../popup/AnnotatedSymbolPopup/AnnotatedSymbolHoverPopup";
import AnnotatedSymbolPopup from "../../../popup/AnnotatedSymbolPopup/AnnotatedSymbolPopup";
import useLayerInteractions from "../../MapInteractions/useLayerInteractions";
import { ANNOTATED_SYMBOL_LAYER_ID } from "./constants";
import { getPropertiesFromFeature } from "./interactions";
import type { SymbolAnnotation } from "./types";
import useAnnotatedSymbolMapData from "./useAnnotatedSymbolMapData";
import useAnnotatedSymbolMapLayers from "./useAnnotatedSymbolMapLayers";

interface AnnotatedSymbolLayerProps {
  annotations: SymbolAnnotation[];
  incidentId: string;
}

const AnnotatedSymbolLayer = ({
  annotations,
  incidentId,
}: AnnotatedSymbolLayerProps) => {
  useAnnotatedSymbolMapLayers();
  useAnnotatedSymbolMapData({ annotations });

  const {
    deactivateClickState,
    deactivateHoverState,
    clickedState,
    hoveredState,
  } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: ANNOTATED_SYMBOL_LAYER_ID,
  });

  return (
    <>
      <AnnotatedSymbolHoverPopup
        onClose={deactivateHoverState}
        state={hoveredState}
      />
      <AnnotatedSymbolPopup
        incidentId={incidentId}
        onClose={deactivateClickState}
        state={clickedState}
      />
    </>
  );
};

export default AnnotatedSymbolLayer;
