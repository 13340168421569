import type { GetPropertiesFromFeatureFn } from "../../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../../MapInteractions/types";
import { isLineStringFeature } from "../../types";
import {
  isLineAnnotationFeature,
  type LineAnnotationProperties,
} from "./types";

type AnnotatedLineInteractionProperties = FeatureInteractionProperties & {
  data: LineAnnotationProperties;
};

export type AnnotatedLineInteractionState =
  FeatureInteractionState<AnnotatedLineInteractionProperties>;

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  AnnotatedLineInteractionProperties
> = (feature, event) => {
  if (!isLineStringFeature(feature) || !isLineAnnotationFeature(feature)) {
    return null;
  }

  const { lngLat } = event;

  return {
    data: {
      annotationId: feature.properties.annotationId,
    },
    featureId: feature.properties.annotationId,
    lngLat,
  };
};
