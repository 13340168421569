import { FieldGrid, Text } from "@app/design-system";
import { useEffect } from "react";
import styled from "styled-components";
import { MapLevel } from "../../../config/layers/layers";
import { EMDASH } from "../../../lib/strings";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import PreviewSpatialPopup from "../../popup/PreviewSpatialPopup/PreviewSpatialPopup";
import MapPopupView from "../../popup/SpatialPopup/MapPopupView";
import SpatialPopup from "../../popup/SpatialPopup/SpatialPopup";
import useMapContext from "../Map/useMapContext";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import {
  useMapServerQueryData,
  type QueryOptions,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { loadImage } from "../utils/loadImage";
import { getPropertiesFromFeature } from "./interactions";

const EDUCATION_FACILITIES_ID = "educationFacilities";

const EDUCATION_FACILITIES = {
  EDUCATION_FACILITY_CHILD_CARE_CENTRE:
    "/icons/education-facility-child-care-centre.png",
  EDUCATION_FACILITY_COLLEGE: "/icons/education-facility-college.png",
  EDUCATION_FACILITY_KINDERGARTEN: "/icons/education-facility-kindergarten.png",
  EDUCATION_FACILITY_PRIMARY: "/icons/education-facility-primary.png",
  EDUCATION_FACILITY_SECONDARY: "/icons/education-facility-secondary.png",
  EDUCATION_FACILITY_TAFE: "/icons/education-facility-tafe.png",
  EDUCATION_FACILITY_UNIVERSITY: "/icons/education-facility-university.png",
  EDUCATION_FACILITY_UNKNOWN: "/icons/education-facility-unknown.png",
};

const EDUCATION_FACILITIES_QUERY_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/BaseDynamicData/MapServer/11/query`;
const QUERY_OPTIONS = {
  queryParams: {
    outFields: [
      "OBJECTID",
      "symbolname",
      "facname",
      "locality",
      "contact",
      "phone1",
    ].join(","),
  },
} as const satisfies QueryOptions;

const StyledPopupContent = styled.div`
  display: grid;
  gap: 0.5rem;
  text-align: left;
`;

interface EducationFacilitiesLayerProps {
  opacity?: number;
}

const EducationFacilitiesLayer = ({
  opacity = 1,
}: EducationFacilitiesLayerProps) => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(
    EDUCATION_FACILITIES_QUERY_URL,
    QUERY_OPTIONS,
  );

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(EDUCATION_FACILITIES_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    Promise.all(
      Object.entries(EDUCATION_FACILITIES).map(([imageId, src]) =>
        loadImage({ imageId, map, src, signal: controller.signal }),
      ),
    ).then(() => {
      map.addLayer(
        {
          id: EDUCATION_FACILITIES_ID,
          type: "symbol",
          source: EDUCATION_FACILITIES_ID,
          layout: {
            "icon-image": [
              "coalesce",
              ["image", ["get", "symbolname"]],
              ["image", "EDUCATION_FACILITY_UNKNOWN"],
            ],
            "icon-allow-overlap": true,
            "icon-optional": false,
          },
        },
        MapLevel.SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      controller.abort();
      if (map.getLayer(EDUCATION_FACILITIES_ID)) {
        map.removeLayer(EDUCATION_FACILITIES_ID);
      }
      if (map.getSource(EDUCATION_FACILITIES_ID)) {
        map.removeSource(EDUCATION_FACILITIES_ID);
      }
      Object.keys(EDUCATION_FACILITIES).forEach((id) => {
        if (map.hasImage(id)) {
          map.removeImage(id);
        }
      });
    };
  }, [map]);

  useEffect(() => {
    const source = map.getSource(EDUCATION_FACILITIES_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  useEffect(() => {
    map.setPaintProperty(EDUCATION_FACILITIES_ID, "icon-opacity", opacity);
  }, [opacity, map]);

  const {
    clickedState,
    hoveredState,
    deactivateHoverState,
    deactivateClickState,
  } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: EDUCATION_FACILITIES_ID,
  });

  return (
    <>
      <PreviewSpatialPopup onClose={deactivateHoverState} state={hoveredState}>
        {hoveredState.properties?.name || EMDASH}
      </PreviewSpatialPopup>

      <SpatialPopup
        isOpen={clickedState.isActive}
        lngLat={clickedState.properties?.lngLat}
        onClose={deactivateClickState}
        popupConfig={{
          anchor: "bottom",
          id: clickedState.id,
          offset: 8,
          size: "lg",
          type: "click",
        }}
      >
        <MapPopupView
          hasClose
          header={
            <Text size="eyebrowDefault" variant="weak">
              Education facility
            </Text>
          }
          onClose={deactivateClickState}
        >
          <StyledPopupContent>
            <Text size="subtitleMd">
              {clickedState.properties?.name || EMDASH}
            </Text>
            <FieldGrid>
              <FieldGrid.Item label="Locality:">
                {clickedState.properties?.locality || EMDASH}
              </FieldGrid.Item>
              <FieldGrid.Item label="Contact:">
                {clickedState.properties?.contact || EMDASH}
              </FieldGrid.Item>
              <FieldGrid.Item label="Phone:">
                {clickedState.properties?.phone || EMDASH}
              </FieldGrid.Item>
            </FieldGrid>
          </StyledPopupContent>
        </MapPopupView>
      </SpatialPopup>
    </>
  );
};

export default EducationFacilitiesLayer;
