import type { Position } from "geojson";
import { useEffect, useState } from "react";
import useEscapeKeyDown from "../../../hooks/useEscapeKeyDown";
import { useVisualiser } from "../../ui/Visualiser/VisualiserProvider";
import useMapContext from "../Map/useMapContext";
import type { MapboxMouseEvent } from "../types";
import FlagDataModal from "./FlagDataModal";

interface FlagDataLayerProps {
  incidentId: string;
}

const FlagDataLayer = ({ incidentId }: FlagDataLayerProps) => {
  const { onToolComplete } = useVisualiser();
  const map = useMapContext();

  const [position, setPosition] = useState<Position | null>(null);

  useEffect(() => {
    map.getCanvas().style.cursor = "crosshair";

    const onClick = (event: MapboxMouseEvent) => {
      const { lng, lat } = event.lngLat.wrap();
      setPosition([lng, lat]);
    };

    map.on("click", onClick);

    return () => {
      map.getCanvas().style.cursor = "";
      map.off("click", onClick);
    };
  }, [map]);

  const onEscapeKeyDown = () => {
    if (position) return;

    onToolComplete();
  };

  const onClose = () => {
    onToolComplete();
  };

  useEscapeKeyDown(onEscapeKeyDown);

  const isFlagDataModalOpen = !!position;

  return (
    <FlagDataModal
      incidentId={incidentId}
      isOpen={isFlagDataModalOpen}
      onClose={onClose}
      position={position}
    />
  );
};

export default FlagDataLayer;
