import { Checkbox, Field, TextArea, TextInput } from "@app/design-system";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import type { GISCorrection } from "../../../../.rest-hooks/types";

export interface EditFlaggedDataFormValues {
  confirmed: boolean;
  dataLayer?: string;
  note: string;
}

export const getDefaultEditFlaggedDataFormValues = (
  flaggedData: GISCorrection,
): EditFlaggedDataFormValues => {
  return {
    confirmed: flaggedData.attributes.confirmed,
    dataLayer: flaggedData.attributes.dataLayer,
    note: flaggedData.attributes.note,
  };
};

const StyledEditFlaggedDataForm = styled.div`
  display: grid;
  gap: 8px;
`;

const EditFlaggedDataForm = () => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<EditFlaggedDataFormValues>();

  return (
    <StyledEditFlaggedDataForm>
      <Field error={errors.note} htmlFor="note" label="Note">
        <TextArea
          autoFocus
          id="note"
          validationStatus={errors.note && "error"}
          {...register("note", { required: "Please enter a note" })}
        />
      </Field>
      <Field error={errors.dataLayer} htmlFor="dataLayer" label="Data layer">
        <TextInput id="dataLayer" disabled {...register("dataLayer")} />
      </Field>
      <Controller
        control={control}
        name="confirmed"
        render={({ field: { onChange, value, ...field } }) => {
          return (
            <Checkbox
              {...field}
              checked={value}
              label="Mark as confirmed"
              onChange={(event) => {
                onChange(event.currentTarget.checked);
              }}
            />
          );
        }}
      />
    </StyledEditFlaggedDataForm>
  );
};

export default EditFlaggedDataForm;
