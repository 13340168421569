import {
  Checkbox,
  Field,
  FileDisplayInput,
  TextArea,
  TextInput,
} from "@app/design-system";
import type { Position } from "geojson";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import formatCoordinates from "../../../config/formatCoordinates";
import type { FileUploadResult } from "../../../hooks/useUploadFile";

export interface CreateMediaAnnotationFormValues {
  author: string;
  confirmed: boolean;
  location: string;
  mediaFileName: string;
  note: string;
}

export const defaultUploadMediaFormValues: CreateMediaAnnotationFormValues = {
  author: "",
  confirmed: false,
  location: "",
  mediaFileName: "",
  note: "",
};

const StyledCreateMediaAnnotationForm = styled.div`
  display: grid;
  gap: 8px;
`;

interface GetDefaultCreateMediaAnnotationFormValuesParams {
  fileUploadResult: FileUploadResult | null;
  position: Position | null;
}

export const getDefaultCreateMediaAnnotationFormValues = ({
  fileUploadResult,
  position,
}: GetDefaultCreateMediaAnnotationFormValuesParams): CreateMediaAnnotationFormValues => {
  let location = "";
  if (position) {
    location = formatCoordinates(position);
  }

  return {
    author: "",
    confirmed: false,
    location,
    mediaFileName: fileUploadResult?.filename ?? "",
    note: "",
  };
};

const CreateMediaAnnotationForm = () => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<CreateMediaAnnotationFormValues>();

  return (
    <StyledCreateMediaAnnotationForm>
      <Field htmlFor="mediaFilePlaceholder" label="Media">
        <Controller
          control={control}
          name="mediaFileName"
          render={({ field }) => {
            return <FileDisplayInput value={field.value} />;
          }}
        />
      </Field>
      <Field error={errors.author} htmlFor="author" label="Author">
        <TextInput
          disabled
          id="author"
          placeholder="Author"
          {...register("author")}
          validationStatus={errors.author && "error"}
        />
      </Field>
      <Field error={errors.location} htmlFor="location" label="Location">
        <TextInput
          id="location"
          {...register("location")}
          validationStatus={errors.location && "error"}
        />
      </Field>
      <Field error={errors.note} htmlFor="note" label="Note">
        <TextArea
          id="note"
          placeholder="Add information about your note here"
          {...register("note", { required: "Please enter a note" })}
          validationStatus={errors.note && "error"}
        />
      </Field>
      <Controller
        control={control}
        name="confirmed"
        render={({ field: { onChange, value, ...field } }) => {
          return (
            <Checkbox
              {...field}
              checked={value}
              label="Mark as confirmed"
              onChange={(event) => {
                onChange(event.currentTarget.checked);
              }}
            />
          );
        }}
      />
    </StyledCreateMediaAnnotationForm>
  );
};

export default CreateMediaAnnotationForm;
