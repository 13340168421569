import { ConfirmModal, showToast } from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import {
  getGetIncidentsIdGisCorrectionsQueryKey,
  useDeleteGisCorrectionsId,
} from "../../../../.rest-hooks/gis-corrections";
import type { Incident } from "../../../../.rest-hooks/types";
import { getDeleteAnnotationModalCopy } from "../utils/DeleteAnnotationModal";

interface DeleteFlaggedDataModalProps {
  flaggedDataId: string;
  incident: Incident | undefined;
  incidentId: string;
  isOpen: boolean;
  onCancel: () => void;
  onDeleteSuccess: () => void;
}

const DeleteFlaggedDataModal = ({
  flaggedDataId,
  incidentId,
  incident,
  isOpen,
  onCancel,
  onDeleteSuccess,
}: DeleteFlaggedDataModalProps) => {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteMediaAnnotationMutation } =
    useDeleteGisCorrectionsId({
      mutation: {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: getGetIncidentsIdGisCorrectionsQueryKey(incidentId),
          });

          showToast({
            variant: "success",
            title: "Flag deleted",
          });
        },
        onError: () => {
          showToast({
            variant: "error",
            title: "Unable to delete flag",
          });
        },
      },
    });

  const onConfirm = () => {
    return deleteMediaAnnotationMutation({
      id: flaggedDataId,
    });
  };

  return (
    <ConfirmModal
      confirmLabel="Delete"
      title="Delete markup?"
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      onConfirmSuccess={onDeleteSuccess}
    >
      {getDeleteAnnotationModalCopy({
        annotationType: "flagged data annotation",
        incident,
        incidentId,
      })}
    </ConfirmModal>
  );
};

export default DeleteFlaggedDataModal;
