import type { Feature, FeatureCollection, Point } from "geojson";

export interface FlaggedDataProperties {
  flaggedDataId: string;
}

export type FlaggedDataFeature = Feature<Point, FlaggedDataProperties>;

export type FlaggedDataFeatureCollection = FeatureCollection<
  Point,
  FlaggedDataProperties
>;

export const isFlaggedDataFeature = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  feature: Feature<Point, any>,
): feature is FlaggedDataFeature => {
  return !!feature.properties?.flaggedDataId;
};
