export const DATA_LAYER_OPTIONS = [
  { value: "Other", label: "Other" },
  { value: "Accommodation facilities", label: "Accommodation facilities" },
  { value: "Aged care facilities", label: "Aged care facilities" },
  { value: "Airports & helipads", label: "Airports & helipads" },
  { value: "AVL", label: "AVL" },
  { value: "Communication towers", label: "Communication towers" },
  { value: "Education facilities", label: "Education facilities" },
  { value: "Emergency facilities", label: "Emergency facilities" },
  { value: "GRN", label: "GRN" },
  { value: "Habitable properties", label: "Habitable properties" },
  { value: "Health facilities", label: "Health facilities" },
  { value: "Land use", label: "Land use" },
  { value: "Mobile blackspots", label: "Mobile blackspots" },
  {
    value: "FRNSW jurisdictional boundaries",
    label: "FRNSW jurisdictional boundaries",
  },
  { value: "Oil & gas pipelines", label: "Oil & gas pipelines" },
  { value: "Power infrastructure", label: "Power infrastructure" },
  {
    value: "RFS jurisdictional boundaries",
    label: "RFS jurisdictional boundaries",
  },
  { value: "Traffic", label: "Traffic" },
];
