import type { Feature, FeatureCollection, Point } from "geojson";
import type { Annotation } from "../../../../../.rest-hooks/types";
import type { AnnotationTextAttributes } from "../../../../../.rest-hooks/types/annotations.yml";

export type TextAnnotation = Annotation & {
  attributes: AnnotationTextAttributes;
};

export const isTextAnnotation = (
  annotation: Annotation,
): annotation is TextAnnotation => {
  return annotation.attributes.annotationType === "text";
};

export interface TextAnnotationProperties {
  annotationId: string;
}

export type TextAnnotationFeature = Feature<Point, TextAnnotationProperties>;

export type TextAnnotationFeatureCollection = FeatureCollection<
  Point,
  TextAnnotationProperties
>;

export const isTextAnnotationFeature = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  feature: Feature<Point, any>,
): feature is TextAnnotationFeature => {
  return !!feature.properties?.annotationId;
};
