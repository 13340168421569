import { useEffect } from "react";
import useMapContext from "../../Map/useMapContext";
import { isGeoJsonSource } from "../../types";
import { ANNOTATED_LINE_SOURCE_ID } from "./constants";
import type {
  LineAnnotation,
  LineAnnotationFeature,
  LineAnnotationFeatureCollection,
} from "./types";

interface UseAnnotatedLineMapDataParams {
  annotations: LineAnnotation[];
}

const useAnnotatedLineMapData = ({
  annotations,
}: UseAnnotatedLineMapDataParams) => {
  const map = useMapContext();

  useEffect(() => {
    const setData = () => {
      const source = map.getSource(ANNOTATED_LINE_SOURCE_ID);
      if (!isGeoJsonSource(source)) {
        return;
      }

      const features = annotations.map<LineAnnotationFeature>(
        (lineAnnotation) => ({
          type: "Feature",
          properties: {
            annotationId: lineAnnotation.id,
            note: lineAnnotation.attributes.note,
            type: lineAnnotation.attributes.annotationType,
          },
          geometry: lineAnnotation.attributes.geometry,
        }),
      );

      const featureCollection: LineAnnotationFeatureCollection = {
        type: "FeatureCollection",
        features,
      };

      source.setData(featureCollection);
    };

    setData();
  }, [annotations, map]);
};

export default useAnnotatedLineMapData;
