import { Alert } from "@app/design-system";
import { useGetAnnotationsId } from "../../../../.rest-hooks/annotations";
import type { AnnotatedTextInteractionState } from "../../map/IncidentAnnotations/AnnotatedText/interactions";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import AnnotationHoverPopupView from "../utils/AnnotationHoverPopupView";
import SkeletonAnnotationHoverPopupView from "../utils/SkeletonAnnotationHoverPopupView";

interface AnnotatedTextHoverPopupProps {
  onClose: () => void;
  state: AnnotatedTextInteractionState;
}

const AnnotatedTextHoverPopup = ({
  onClose,
  state,
}: AnnotatedTextHoverPopupProps) => {
  const annotationId = state.properties?.data.annotationId ?? "";

  const {
    data: annotationData,
    isError,
    isPending,
  } = useGetAnnotationsId(annotationId);

  let content: React.ReactNode;

  if (isError) {
    content = (
      <MapPopupView header="Text annotation" onClose={onClose} hasClose>
        <Alert variant="error" title="Unable to load annotation" />
      </MapPopupView>
    );
  } else if (isPending) {
    content = <SkeletonAnnotationHoverPopupView onClose={onClose} />;
  } else {
    const annotationContents = annotationData.data.data;

    if (!annotationContents) {
      content = (
        <MapPopupView header="Text annotation" onClose={onClose} hasClose>
          <Alert variant="error" title="Invalid annotation" />
        </MapPopupView>
      );
    } else {
      content = (
        <AnnotationHoverPopupView
          annotation={annotationContents}
          onClose={onClose}
        />
      );
    }
  }

  return (
    <SpatialPopup
      isOpen={state.isActive}
      onClose={onClose}
      popupConfig={{
        id: state.id,
        offset: 16,
        size: "lg",
        type: "hover",
        variant: "inverse",
      }}
      lngLat={state.properties?.lngLat}
    >
      {content}
    </SpatialPopup>
  );
};

export default AnnotatedTextHoverPopup;
