import { useEffect } from "react";
import { useGetPermissions } from "../../../../.rest-hooks/permissions";
import { showMarkupLayer } from "../../../config/layers/layers";
import { useVisualiser } from "../../ui/Visualiser/VisualiserProvider";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import FlagData from "../FlagData/FlagData";
import IncidentAnnotations from "../IncidentAnnotations/IncidentAnnotations";
import UploadMedia from "../UploadMedia/UploadMedia";
import LineAnnotation from "../annotations/LineAnnotation/LineAnnotation";
import PolygonAnnotation from "../annotations/PolygonAnnotation/PolygonAnnotation";
import SymbolAnnotation from "../annotations/SymbolAnnotation/SymbolAnnotation";
import TextAnnotation from "../annotations/TextAnnotation/TextAnnotation";

interface IncidentMarkupProps {
  incidentId: string;
}

const IncidentMarkup = ({ incidentId }: IncidentMarkupProps) => {
  const {
    visualiserState: { activeTool },
  } = useVisualiser();

  const { activateLayer, isLayerActive } = useActiveLayersContext();

  useEffect(() => {
    if (activeTool && !isLayerActive(showMarkupLayer.id)) {
      activateLayer({
        id: showMarkupLayer.id,
        source: "option-layer",
      });
    }
  }, [activateLayer, activeTool, isLayerActive]);

  const { data } = useGetPermissions();
  const userPermissions = data?.data.meta.permissions;

  return userPermissions?.includes("annotation:read") ? (
    <>
      <IncidentAnnotations incidentId={incidentId} />
      <LineAnnotation incidentId={incidentId} />
      <PolygonAnnotation incidentId={incidentId} />
      <SymbolAnnotation incidentId={incidentId} />
      <TextAnnotation incidentId={incidentId} />
      <UploadMedia incidentId={incidentId} />
      <FlagData incidentId={incidentId} />
    </>
  ) : null;
};

export default IncidentMarkup;
