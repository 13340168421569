import { Field, TextArea } from "@app/design-system";
import { useFormContext } from "react-hook-form";
import type { LineAnnotation } from "../../map/IncidentAnnotations/AnnotatedLine/types";

export interface EditAnnotatedLineFormValues {
  note: string;
}

export const getDefaultEditAnnotatedLineFormValues = (
  annotation: LineAnnotation,
): EditAnnotatedLineFormValues => {
  return {
    note: annotation.attributes.note,
  };
};

const EditAnnotatedLineForm = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<EditAnnotatedLineFormValues>();

  return (
    <Field error={errors.note} htmlFor="note" label="Note">
      <TextArea
        id="note"
        validationStatus={errors.note && "error"}
        maxLength={300}
        {...register("note", { required: "Please enter a note" })}
      />
    </Field>
  );
};

export default EditAnnotatedLineForm;
