import UploadedMediaHoverPopup from "../../../popup/UploadedMediaPopup/UploadedMediaHoverPopup";
import UploadedMediaPopup from "../../../popup/UploadedMediaPopup/UploadedMediaPopup";
import useLayerInteractions from "../../MapInteractions/useLayerInteractions";
import { UPLOADED_MEDIA_LAYER_ID } from "./constants";
import { getPropertiesFromFeature } from "./interactions";
import type { MediaAnnotation } from "./types";
import useUploadedMediaMapData from "./useUploadedMediaMapData";
import useUploadedMediaMapLayers from "./useUploadedMediaMapLayers";

interface UploadedMediaLayerProps {
  annotations: MediaAnnotation[];
  incidentId: string;
}

const UploadedMediaLayer = ({
  annotations,
  incidentId,
}: UploadedMediaLayerProps) => {
  useUploadedMediaMapLayers();
  useUploadedMediaMapData({ annotations });

  const {
    deactivateClickState,
    deactivateHoverState,
    clickedState,
    hoveredState,
  } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: UPLOADED_MEDIA_LAYER_ID,
  });

  return (
    <>
      <UploadedMediaHoverPopup
        onClose={deactivateHoverState}
        state={hoveredState}
      />
      <UploadedMediaPopup
        incidentId={incidentId}
        onClose={deactivateClickState}
        state={clickedState}
      />
    </>
  );
};

export default UploadedMediaLayer;
