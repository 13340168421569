import type { Feature, FeatureCollection, Point } from "geojson";
import type { Annotation } from "../../../../../.rest-hooks/types";
import type { AnnotationSymbolAttributes } from "../../../../../.rest-hooks/types/annotations.yml";

export type SymbolAnnotation = Annotation & {
  attributes: AnnotationSymbolAttributes;
};

export const isSymbolAnnotation = (
  annotation: Annotation,
): annotation is SymbolAnnotation => {
  return annotation.attributes.annotationType === "symbol";
};

export interface SymbolAnnotationProperties {
  annotationId: string;
  symbolName: string;
}

export type SymbolAnnotationFeature = Feature<
  Point,
  SymbolAnnotationProperties
>;

export type SymbolAnnotationFeatureCollection = FeatureCollection<
  Point,
  SymbolAnnotationProperties
>;

export const isSymbolAnnotationFeature = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  feature: Feature<Point, any>,
): feature is SymbolAnnotationFeature => {
  return !!feature.properties?.annotationId;
};
