import { ModalForm, onPromise, showToast } from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import type { Position } from "geojson";
import { useEffect } from "react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import {
  getGetIncidentsIdAnnotationsQueryKey,
  usePostIncidentsIdAnnotations,
} from "../../../../../.rest-hooks/annotations";
import { isSymbolAnnotation } from "../../IncidentAnnotations/AnnotatedSymbol/types";
import SymbolAnnotationForm, {
  getDefaultSymbolAnnotationFormValues,
  type SymbolAnnotationFormValues,
} from "./SymbolAnnotationForm";

interface SymbolAnnotationModalProps {
  incidentId: string;
  isOpen: boolean;
  onClose: () => void;
  position: Position | null;
  symbolName: string;
}

const SymbolAnnotationModal = ({
  incidentId,
  isOpen,
  onClose,
  position,
  symbolName,
}: SymbolAnnotationModalProps) => {
  const queryClient = useQueryClient();

  const defaultValues = getDefaultSymbolAnnotationFormValues();

  const form = useForm<SymbolAnnotationFormValues>({
    defaultValues,
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(getDefaultSymbolAnnotationFormValues());
  }, [reset]);

  const { mutateAsync: createSymbolAnnotationMutation } =
    usePostIncidentsIdAnnotations({
      mutation: {
        onSuccess: (data) => {
          const annotation = data.data.data;
          if (!isSymbolAnnotation(annotation)) {
            return;
          }
          void queryClient.invalidateQueries({
            queryKey: getGetIncidentsIdAnnotationsQueryKey(incidentId),
          });

          showToast({
            variant: "success",
            title: "Note saved",
          });
        },
        onError: () => {
          showToast({
            variant: "error",
            title: "Unable to save note",
          });
        },
      },
    });

  const onSubmit: SubmitHandler<SymbolAnnotationFormValues> = async (
    values,
  ) => {
    if (!position) {
      return null;
    }

    await createSymbolAnnotationMutation({
      id: incidentId,
      data: {
        annotationType: "symbol",
        note: values.note,
        symbolName,
        geometry: {
          type: "Point",
          coordinates: position,
        },
      },
    });

    onClose();
  };

  return (
    <ModalForm
      id="symbolAnnotation"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onCancel={onClose}
      onSubmit={onPromise(handleSubmit(onSubmit))}
      submitLabel="Save"
      title="Add note"
    >
      <FormProvider {...form}>
        <SymbolAnnotationForm />
      </FormProvider>
    </ModalForm>
  );
};

export default SymbolAnnotationModal;
