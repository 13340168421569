import { ConfirmModal, showToast } from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import {
  getGetIncidentsIdAnnotationsQueryKey,
  useDeleteAnnotationsId,
} from "../../../../.rest-hooks/annotations";
import type { Incident } from "../../../../.rest-hooks/types";
import { getDeleteAnnotationModalCopy } from "../utils/DeleteAnnotationModal";

interface DeleteMediaAnnotationModalProps {
  annotationId: string;
  incident: Incident | undefined;
  incidentId: string;
  isOpen: boolean;
  onCancel: () => void;
  onDeleteSuccess: () => void;
}

const DeleteMediaAnnotationModal = ({
  annotationId,
  incidentId,
  incident,
  isOpen,
  onCancel,
  onDeleteSuccess,
}: DeleteMediaAnnotationModalProps) => {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteMediaAnnotationMutation } = useDeleteAnnotationsId(
    {
      mutation: {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: getGetIncidentsIdAnnotationsQueryKey(incidentId),
          });

          showToast({
            variant: "success",
            title: "Media deleted",
          });
        },
        onError: () => {
          showToast({
            variant: "error",
            title: "Unable to delete media",
          });
        },
      },
    },
  );

  const onConfirm = () => {
    return deleteMediaAnnotationMutation({
      id: annotationId,
    });
  };

  return (
    <ConfirmModal
      confirmLabel="Delete"
      title="Delete markup?"
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      onConfirmSuccess={onDeleteSuccess}
    >
      {getDeleteAnnotationModalCopy({
        annotationType: "uploaded media",
        incident,
        incidentId,
      })}
    </ConfirmModal>
  );
};

export default DeleteMediaAnnotationModal;
