import { useEffect } from "react";
import { MapLevel } from "../../../../config/layers/layers";
import useMapContext from "../../Map/useMapContext";
import {
  ANNOTATED_LINE_LAYER_ID,
  ANNOTATED_LINE_SOURCE_ID,
  ANNOTATED_LINE_TEXT_LAYER_ID,
} from "./constants";

const useAnnotatedLineMapLayers = () => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(ANNOTATED_LINE_SOURCE_ID, {
      type: "geojson",
      data: {
        features: [],
        type: "FeatureCollection",
      },
    });

    map.addLayer(
      {
        id: ANNOTATED_LINE_LAYER_ID,
        type: "line",
        source: ANNOTATED_LINE_SOURCE_ID,
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#000",
          "line-width": 2,
        },
      },
      MapLevel.FS_LABELS,
    );

    map.addLayer(
      {
        id: ANNOTATED_LINE_TEXT_LAYER_ID,
        type: "symbol",
        source: ANNOTATED_LINE_SOURCE_ID,
        layout: {
          "text-field": ["get", "note"],
          "text-anchor": "center",
          "text-offset": [0, -1],
          "text-transform": "uppercase",
          "text-size": ["interpolate", ["linear"], ["zoom"], 5, 10, 10, 18],
          "symbol-placement": "line",
          "symbol-spacing": 500,
        },
        paint: {
          "text-color": "#000000",
        },
      },
      MapLevel.FS_LABELS,
    );

    return () => {
      if (map.getLayer(ANNOTATED_LINE_TEXT_LAYER_ID)) {
        map.removeLayer(ANNOTATED_LINE_TEXT_LAYER_ID);
      }
      if (map.getLayer(ANNOTATED_LINE_LAYER_ID)) {
        map.removeLayer(ANNOTATED_LINE_LAYER_ID);
      }
      if (map.getSource(ANNOTATED_LINE_SOURCE_ID)) {
        map.removeSource(ANNOTATED_LINE_SOURCE_ID);
      }
    };
  }, [map]);
};

export default useAnnotatedLineMapLayers;
