import styled from "styled-components";
import { useGetPermissions } from "../../../../.rest-hooks/permissions";
import makeTestId from "../../../utils/makeTestId";
import { useVisualiser } from "../../ui/Visualiser/VisualiserProvider";
import MarkupToolsetsListAccordion from "./MarkupToolsetsListAccordion";
import ShowMarkupController from "./ShowMarkupController";

const StyledMarkupToolsetsList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.neutrals.surface};
  border: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
`;

interface MarkupToolsetsListProps {
  "data-testid"?: string;
}

const MarkupToolsetsList = ({
  "data-testid": dataTestId,
}: MarkupToolsetsListProps) => {
  const {
    visualiserState: { toolsets },
  } = useVisualiser();

  const { data } = useGetPermissions();
  const userPermissions = data?.data.meta.permissions;

  return (
    <StyledMarkupToolsetsList>
      <ShowMarkupController />
      {userPermissions?.includes("annotation:write") &&
        toolsets.map((toolset) => {
          return (
            <MarkupToolsetsListAccordion
              key={toolset.value}
              title={toolset.label}
              tools={toolset.tools}
              data-testid={makeTestId(dataTestId, toolset.value)}
            />
          );
        })}
    </StyledMarkupToolsetsList>
  );
};

export default MarkupToolsetsList;
