import { ModalForm, onPromise } from "@app/design-system";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import {
  type FileUploadResult,
  useUploadFile,
} from "../../../hooks/useUploadFile";
import UploadMediaForm, {
  defaultUploadMediaFormValues,
  type UploadMediaFormValues,
} from "./UploadMediaForm";

interface UploadMediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  onFileUploaded: (result: FileUploadResult) => void;
}

const UploadMediaModal = ({
  isOpen,
  onClose,
  onFileUploaded,
}: UploadMediaModalProps) => {
  const form = useForm<UploadMediaFormValues>({
    defaultValues: defaultUploadMediaFormValues,
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = form;

  const [uploadMedia] = useUploadFile();

  const onSubmit: SubmitHandler<UploadMediaFormValues> = async (values) => {
    const {
      mediaFile: [mediaFile],
    } = values;
    if (!mediaFile) {
      return null;
    }

    const result = await uploadMedia(mediaFile);
    onFileUploaded(result);
  };

  return (
    <ModalForm
      id="uploadMedia"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onCancel={onClose}
      onSubmit={onPromise(handleSubmit(onSubmit))}
      submitLabel="Next"
      title="Upload media"
    >
      <FormProvider {...form}>
        <UploadMediaForm />
      </FormProvider>
    </ModalForm>
  );
};

export default UploadMediaModal;
