export const ANNOTATED_POLYGON_SOURCE_ID = "annotated-polygon";
export const ANNOTATED_POLYGON_LAYER_ID = "annotated-polygon";
export const ANNOTATED_POLYGON_LAYER_OUTLINE_ID = "annotated-polygon-outline";
export const ANNOTATED_POLYGON_TEXT_LAYER_ID = "annotated-polygon-text";

export const FIRE_MAPPING_PATTERNS = {
  "firemap-fire-burnt-area": "/patterns/firemap-fire-burnt-area.png",
  "firemap-fire-emergency-alert": "/patterns/firemap-fire-emergency-alert.png",
  "firemap-fire-previously-burnt-area":
    "/patterns/firemap-fire-previously-burnt-area.png",
  "firemap-fire-proposed-burn-area":
    "/patterns/firemap-fire-proposed-burn-area.png",
};

/*
 * @see https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#match
 */

export const FIRE_MAPPING_PATTERNS_OUTLINES_MAP = [
  "firemap-fire-burnt-area",
  "transparent",
  "firemap-fire-emergency-alert",
  "#d11212",
  "firemap-fire-previously-burnt-area",
  "#000",
  "firemap-fire-proposed-burn-area",
  "#000",
  "#000",
] as const;
