import { Field, TextArea } from "@app/design-system";
import { useFormContext } from "react-hook-form";
import type { PolygonAnnotation } from "../../map/IncidentAnnotations/AnnotatedPolygon/types";

export interface EditAnnotatedPolygonFormValues {
  note: string;
}

export const getDefaultEditAnnotatedPolygonFormValues = (
  annotation: PolygonAnnotation,
): EditAnnotatedPolygonFormValues => {
  return {
    note: annotation.attributes.note,
  };
};

const EditAnnotatedPolygonForm = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<EditAnnotatedPolygonFormValues>();

  return (
    <Field error={errors.note} htmlFor="note" label="Note">
      <TextArea
        id="note"
        validationStatus={errors.note && "error"}
        maxLength={300}
        {...register("note", { required: "Please enter a note" })}
      />
    </Field>
  );
};

export default EditAnnotatedPolygonForm;
