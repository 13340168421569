import type { Position } from "geojson";
import { useEffect, useState } from "react";
import useEscapeKeyDown from "../../../hooks/useEscapeKeyDown";
import type { FileUploadResult } from "../../../hooks/useUploadFile";
import { useVisualiser } from "../../ui/Visualiser/VisualiserProvider";
import useMapContext from "../Map/useMapContext";
import type { MapboxMouseEvent } from "../types";
import CreateMediaAnnotationModal from "./CreateMediaAnnotationModal";
import UploadMediaModal from "./UploadMediaModal";

interface UploadMediaLayerProps {
  incidentId: string;
}

const UploadMediaLayer = ({ incidentId }: UploadMediaLayerProps) => {
  const { onToolComplete } = useVisualiser();
  const map = useMapContext();

  const [position, setPosition] = useState<Position | null>(null);

  const [fileUploadResult, setFileUploadResult] =
    useState<FileUploadResult | null>(null);

  const onFileUploaded = (result: FileUploadResult) => {
    setFileUploadResult(result);
  };

  useEffect(() => {
    map.getCanvas().style.cursor = "crosshair";

    const onClick = (event: MapboxMouseEvent) => {
      const { lng, lat } = event.lngLat.wrap();
      setPosition([lng, lat]);
    };

    map.on("click", onClick);

    return () => {
      map.getCanvas().style.cursor = "";
      map.off("click", onClick);
    };
  }, [map]);

  const onEscapeKeyDown = () => {
    if (position) return;

    onToolComplete();
  };

  useEscapeKeyDown(onEscapeKeyDown);

  const onClose = () => {
    onToolComplete();
  };

  const isUploadMediaModalOpen = !!position && !fileUploadResult;
  const isCreateMediaAnnotationModalOpen = !!position && !!fileUploadResult;

  return (
    <>
      <UploadMediaModal
        isOpen={isUploadMediaModalOpen}
        onClose={onClose}
        onFileUploaded={onFileUploaded}
      />
      <CreateMediaAnnotationModal
        fileUploadResult={fileUploadResult}
        incidentId={incidentId}
        isOpen={isCreateMediaAnnotationModalOpen}
        onClose={onClose}
        position={position}
      />
    </>
  );
};

export default UploadMediaLayer;
