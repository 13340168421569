import { showToast } from "@app/design-system";
import { useEffect } from "react";
import { useGetIncidentsIdAnnotations } from "../../../../.rest-hooks/annotations";
import AnnotatedLineLayer from "./AnnotatedLine/AnnotatedLineLayer";
import { isLineAnnotation } from "./AnnotatedLine/types";
import AnnotatedPolygonLayer from "./AnnotatedPolygon/AnnotatedPolygonLayer";
import { isPolygonAnnotation } from "./AnnotatedPolygon/types";
import AnnotatedSymbolLayer from "./AnnotatedSymbol/AnnotatedSymbolLayer";
import { isSymbolAnnotation } from "./AnnotatedSymbol/types";
import AnnotatedTextLayer from "./AnnotatedText/AnnotatedTextLayer";
import { isTextAnnotation } from "./AnnotatedText/types";
import FlaggedData from "./FlaggedData/FlaggedData";
import UploadedMediaLayer from "./UploadedMedia/UploadedMediaLayer";
import { isMediaAnnotation } from "./UploadedMedia/types";

type IncidentAnnotationsLayerProps = {
  incidentId: string;
};

export const IncidentAnnotationsLayer = ({
  incidentId,
}: IncidentAnnotationsLayerProps) => {
  const { data, isError } = useGetIncidentsIdAnnotations(incidentId);

  useEffect(() => {
    if (!isError) return;

    showToast({
      variant: "error",
      title: "Unable to load annotations",
    });
  }, [isError]);

  if (!data) return null;

  return (
    <>
      <AnnotatedLineLayer
        annotations={data.data.data.filter(isLineAnnotation)}
        incidentId={incidentId}
      />
      <AnnotatedPolygonLayer
        annotations={data.data.data.filter(isPolygonAnnotation)}
        incidentId={incidentId}
      />
      <AnnotatedSymbolLayer
        annotations={data.data.data.filter(isSymbolAnnotation)}
        incidentId={incidentId}
      />
      <AnnotatedTextLayer
        annotations={data.data.data.filter(isTextAnnotation)}
        incidentId={incidentId}
      />
      <FlaggedData incidentId={incidentId} />
      <UploadedMediaLayer
        annotations={data.data.data.filter(isMediaAnnotation)}
        incidentId={incidentId}
      />
    </>
  );
};
