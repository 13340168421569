import {
  MapLevel,
  mobileCoverageTpgLayer,
} from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import MobileCoverageLayer from "./MobileCoverageLayer";

const MobileCoverageTpg = () => {
  const { isLayerActive, getLayerStateFromConfig } = useActiveLayersContext();

  if (!isLayerActive(mobileCoverageTpgLayer.id)) {
    return null;
  }

  const state = getLayerStateFromConfig(mobileCoverageTpgLayer);

  return (
    <>
      {mobileCoverageTpgLayer.subLayers.map(({ id }) =>
        state?.[id]?.isActive ? (
          <MobileCoverageLayer
            level={
              id === "mobileCoverageTpgSitesLayer"
                ? MapLevel.SYMBOLS
                : MapLevel.BACKGROUND
            }
            key={id}
            opacity={state?.opacity}
            subLayerId={id}
          />
        ) : null,
      )}
    </>
  );
};

export default MobileCoverageTpg;
