import { Alert, ModalForm, onPromise, showToast } from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import {
  FormProvider,
  type SubmitErrorHandler,
  type SubmitHandler,
  useForm,
} from "react-hook-form";
import {
  getGetAnnotationsIdQueryKey,
  getGetIncidentsIdAnnotationsQueryKey,
  usePatchAnnotationsId,
} from "../../../../.rest-hooks/annotations";
import type { SymbolAnnotation } from "../../map/IncidentAnnotations/AnnotatedSymbol/types";
import EditAnnotatedSymbolForm, {
  type EditAnnotatedSymbolFormValues,
  getDefaultEditAnnotatedSymbolFormValues,
} from "./EditAnnotatedSymbolForm";

interface EditAnnotatedSymbolModalProps {
  annotation: SymbolAnnotation;
  incidentId: string;
  isOpen: boolean;
  onClose: () => void;
}

const EditAnnotatedSymbolModal = ({
  annotation,
  incidentId,
  isOpen,
  onClose,
}: EditAnnotatedSymbolModalProps) => {
  const queryClient = useQueryClient();
  const [isError, setIsError] = useState<boolean>(false);

  const { mutateAsync: editAnnotatedSymbolMutation } = usePatchAnnotationsId({
    mutation: {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: getGetAnnotationsIdQueryKey(annotation.id),
        });
        void queryClient.invalidateQueries({
          queryKey: getGetIncidentsIdAnnotationsQueryKey(incidentId),
        });
        showToast({
          variant: "success",
          title: "Note updated",
        });
      },
    },
  });

  const form = useForm<EditAnnotatedSymbolFormValues>({
    defaultValues: getDefaultEditAnnotatedSymbolFormValues(annotation),
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(getDefaultEditAnnotatedSymbolFormValues(annotation));
  }, [annotation, isOpen, reset]);

  const onSubmit: SubmitHandler<EditAnnotatedSymbolFormValues> = async (
    values,
  ) => {
    setIsError(false);

    try {
      await editAnnotatedSymbolMutation({
        id: annotation.id,
        data: {
          confirmed: values.confirmed,
          note: values.note,
        },
      });
      onClose();
    } catch {
      setIsError(true);
    }
  };

  const onSubmitError: SubmitErrorHandler<
    EditAnnotatedSymbolFormValues
  > = () => {
    setIsError(false);
  };

  return (
    <ModalForm
      error={isError && <Alert variant="error" title="Unable to edit note" />}
      id="editSymbolAnnotation"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onCancel={onClose}
      onSubmit={onPromise(handleSubmit(onSubmit, onSubmitError))}
      submitLabel="Save"
      title="Edit symbol annotation"
    >
      <FormProvider {...form}>
        <EditAnnotatedSymbolForm />
      </FormProvider>
    </ModalForm>
  );
};

export default EditAnnotatedSymbolModal;
