import { useEffect } from "react";
import { MapLevel } from "../../../../config/layers/layers";
import catchAbortError from "../../../../utils/catchAbortError/catchAbortError";
import useMapContext from "../../Map/useMapContext";
import { loadImage } from "../../utils/loadImage";
import {
  ANNOTATED_SYMBOLS,
  ANNOTATED_SYMBOL_LAYER_ID,
  ANNOTATED_SYMBOL_SOURCE_ID,
} from "./constants";

const useAnnotatedSymbolMapLayers = () => {
  const map = useMapContext();

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(ANNOTATED_SYMBOL_SOURCE_ID, {
      type: "geojson",
      data: {
        features: [],
        type: "FeatureCollection",
      },
    });

    Promise.all(
      Object.entries(ANNOTATED_SYMBOLS).map(([imageId, src]) =>
        loadImage({ imageId, map, src, signal: controller.signal }),
      ),
    ).then(() => {
      map.addLayer(
        {
          id: ANNOTATED_SYMBOL_LAYER_ID,
          type: "symbol",
          source: ANNOTATED_SYMBOL_SOURCE_ID,
          layout: {
            "icon-image": ["get", "symbolName"],
            "icon-allow-overlap": true,
            "icon-size": ["interpolate", ["linear"], ["zoom"], 2, 0.1, 15, 1],
          },
        },
        MapLevel.FS_SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      controller.abort();
      if (map.getLayer(ANNOTATED_SYMBOL_LAYER_ID)) {
        map.removeLayer(ANNOTATED_SYMBOL_LAYER_ID);
      }
      if (map.getSource(ANNOTATED_SYMBOL_SOURCE_ID)) {
        map.removeSource(ANNOTATED_SYMBOL_SOURCE_ID);
      }
    };
  }, [map]);
};

export default useAnnotatedSymbolMapLayers;
