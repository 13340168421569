import { useTheme } from "@app/design-system";
import { useEffect } from "react";
import { MapLevel } from "../../../../config/layers/layers";
import useMapContext from "../../Map/useMapContext";
import { isMaplibre } from "../../utils/isMaplibre";
import {
  ANNOTATED_TEXT_CIRCLE_LAYER_ID,
  ANNOTATED_TEXT_LAYER_ID,
  ANNOTATED_TEXT_SOURCE_ID,
} from "./constants";

const useAnnotatedTextMapLayers = () => {
  const map = useMapContext();
  const theme = useTheme();

  useEffect(() => {
    map.addSource(ANNOTATED_TEXT_SOURCE_ID, {
      type: "geojson",
      data: {
        features: [],
        type: "FeatureCollection",
      },
    });

    map.addLayer(
      {
        id: ANNOTATED_TEXT_LAYER_ID,
        type: "symbol",
        source: ANNOTATED_TEXT_SOURCE_ID,
        layout: {
          "text-field": ["get", "note"],
          "text-anchor": "top",
          "text-offset": [0, 1],
          "text-font": isMaplibre(map) ? ["Noto Sans Bold"] : ["DIN Pro Bold"],
          "text-transform": "uppercase",
          "text-size": ["interpolate", ["linear"], ["zoom"], 5, 10, 10, 18],
        },
        paint: {
          "text-color": theme.colors.neutrals.text,
          "text-halo-color": "#FFF",
          "text-halo-width": 1,
          "text-halo-blur": 0,
        },
      },
      MapLevel.FS_LABELS,
    );

    map.addLayer(
      {
        id: ANNOTATED_TEXT_CIRCLE_LAYER_ID,
        type: "circle",
        source: ANNOTATED_TEXT_SOURCE_ID,
        paint: {
          "circle-radius": 5,
          "circle-color": "#000000",
          "circle-stroke-color": "#fff",
          "circle-stroke-width": 1,
        },
      },
      MapLevel.FS_LABELS,
    );

    return () => {
      if (map.getLayer(ANNOTATED_TEXT_LAYER_ID)) {
        map.removeLayer(ANNOTATED_TEXT_LAYER_ID);
      }
      if (map.getLayer(ANNOTATED_TEXT_CIRCLE_LAYER_ID)) {
        map.removeLayer(ANNOTATED_TEXT_CIRCLE_LAYER_ID);
      }
      if (map.getSource(ANNOTATED_TEXT_SOURCE_ID)) {
        map.removeSource(ANNOTATED_TEXT_SOURCE_ID);
      }
    };
  }, [map, theme.colors.neutrals.text]);
};

export default useAnnotatedTextMapLayers;
