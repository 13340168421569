import type { Feature, FeatureCollection, LineString } from "geojson";
import type { Annotation } from "../../../../../.rest-hooks/types";
import type { AnnotationLineAttributes } from "../../../../../.rest-hooks/types/annotations.yml";

export type LineAnnotation = Annotation & {
  attributes: AnnotationLineAttributes;
};

export const isLineAnnotation = (
  annotation: Annotation,
): annotation is LineAnnotation => {
  return annotation.attributes.annotationType === "line";
};

export interface LineAnnotationProperties {
  annotationId: string;
}

export type LineAnnotationFeature = Feature<
  LineString,
  LineAnnotationProperties
>;

export type LineAnnotationFeatureCollection = FeatureCollection<
  LineString,
  LineAnnotationProperties
>;

export const isLineAnnotationFeature = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  feature: Feature<LineString, any>,
): feature is LineAnnotationFeature => {
  return !!feature.properties?.annotationId;
};
