import { ModalForm, onPromise, showToast } from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import {
  getGetIncidentsIdAnnotationsQueryKey,
  usePostIncidentsIdAnnotations,
} from "../../../../../.rest-hooks/annotations";
import { isPolygonAnnotation } from "../../IncidentAnnotations/AnnotatedPolygon/types";
import type { PolygonFeature } from "../../types";
import PolygonAnnotationForm, {
  type PolygonAnnotationFormValues,
  getDefaultPolygonAnnotationFormValues,
} from "./PolygonAnnotationForm";

interface PolygonAnnotationModalProps {
  incidentId: string;
  isOpen: boolean;
  onClose: () => void;
  patternId: string;
  polygonFeature: PolygonFeature | null;
}

const PolygonAnnotationModal = ({
  incidentId,
  isOpen,
  onClose,
  patternId,
  polygonFeature,
}: PolygonAnnotationModalProps) => {
  const queryClient = useQueryClient();

  const defaultValues = getDefaultPolygonAnnotationFormValues();

  const form = useForm<PolygonAnnotationFormValues>({
    defaultValues,
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(getDefaultPolygonAnnotationFormValues());
  }, [reset]);

  const { mutateAsync: createPolygonAnnotationMutation } =
    usePostIncidentsIdAnnotations({
      mutation: {
        onSuccess: (data) => {
          const annotation = data.data.data;
          if (!isPolygonAnnotation(annotation)) {
            return;
          }
          void queryClient.invalidateQueries({
            queryKey: getGetIncidentsIdAnnotationsQueryKey(incidentId),
          });

          showToast({
            variant: "success",
            title: "Polygon annotation saved",
          });
        },
        onError: () => {
          showToast({
            variant: "error",
            title: "Polygon annotation not saved",
          });
        },
      },
    });

  const onSubmit: SubmitHandler<PolygonAnnotationFormValues> = async (
    values,
  ) => {
    if (!polygonFeature) {
      return null;
    }

    await createPolygonAnnotationMutation({
      id: incidentId,
      data: {
        annotationType: "polygon",
        note: values.note,
        patternId,
        geometry: {
          type: "Polygon",
          coordinates: polygonFeature.geometry.coordinates,
        },
      },
    });

    onClose();
  };

  return (
    <ModalForm
      id="polygonAnnotation"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onCancel={onClose}
      onSubmit={onPromise(handleSubmit(onSubmit))}
      submitLabel="Save"
      title="Add note"
    >
      <FormProvider {...form}>
        <PolygonAnnotationForm />
      </FormProvider>
    </ModalForm>
  );
};

export default PolygonAnnotationModal;
