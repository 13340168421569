import { ModalForm, onPromise, showToast } from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import type { Position } from "geojson";
import { useEffect } from "react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import {
  getGetIncidentsIdGisCorrectionsQueryKey,
  usePostIncidentsIdGisCorrections,
} from "../../../../.rest-hooks/gis-corrections";
import FlagDataForm, {
  type FlagDataFormValues,
  getDefaultFlagDataFormValues,
} from "./FlagDataForm";

interface FlagDataModalProps {
  incidentId: string;
  isOpen: boolean;
  onClose: () => void;
  position: Position | null;
}

const FlagDataModal = ({
  incidentId,
  isOpen,
  onClose,
  position,
}: FlagDataModalProps) => {
  const queryClient = useQueryClient();

  const defaultValues = getDefaultFlagDataFormValues();

  const form = useForm<FlagDataFormValues>({
    defaultValues,
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(getDefaultFlagDataFormValues());
  }, [reset]);

  const { mutateAsync: createFlagDataMutation } =
    usePostIncidentsIdGisCorrections({
      mutation: {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: getGetIncidentsIdGisCorrectionsQueryKey(incidentId),
          });

          showToast({
            variant: "success",
            title: "Flag saved",
          });
        },
        onError: () => {
          showToast({
            variant: "error",
            title: "Flag not saved",
            message: "Flag failed",
          });
        },
      },
    });

  const onSubmit: SubmitHandler<FlagDataFormValues> = async (values) => {
    if (!position) {
      return null;
    }

    await createFlagDataMutation({
      id: incidentId,
      data: {
        dataLayer: values.dataLayer?.value,
        note: values.note,
        point: {
          type: "Point",
          coordinates: position,
        },
      },
    });

    onClose();
  };

  return (
    <ModalForm
      id="flagData"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onCancel={onClose}
      onSubmit={onPromise(handleSubmit(onSubmit))}
      submitLabel="Save"
      title="Add note"
    >
      <FormProvider {...form}>
        <FlagDataForm />
      </FormProvider>
    </ModalForm>
  );
};

export default FlagDataModal;
