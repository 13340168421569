import type { GISCorrection } from "../../../../../.rest-hooks/types";
import FlaggedDataHoverPopup from "../../../popup/FlaggedDataPopup/FlaggedDataHoverPopup";
import FlaggedDataPopup from "../../../popup/FlaggedDataPopup/FlaggedDataPopup";
import useLayerInteractions from "../../MapInteractions/useLayerInteractions";
import { FLAGGED_DATA_LAYER_ID } from "./constants";
import { getPropertiesFromFeature } from "./interactions";
import useFlaggedDataMapData from "./useFlaggedDataMapData";
import useFlaggedDataMapLayers from "./useFlaggedDataMapLayers";

interface FlaggedDataLayerProps {
  flaggedData: GISCorrection[];
  incidentId: string;
}

const FlaggedDataLayer = ({
  flaggedData,
  incidentId,
}: FlaggedDataLayerProps) => {
  useFlaggedDataMapLayers();
  useFlaggedDataMapData({ flaggedData });

  const {
    deactivateClickState,
    deactivateHoverState,
    clickedState,
    hoveredState,
  } = useLayerInteractions({
    layerId: FLAGGED_DATA_LAYER_ID,
    getPropertiesFromFeature,
  });

  return (
    <>
      <FlaggedDataHoverPopup
        onClose={deactivateHoverState}
        state={hoveredState}
      />
      <FlaggedDataPopup
        incidentId={incidentId}
        onClose={deactivateClickState}
        state={clickedState}
      />
    </>
  );
};

export default FlaggedDataLayer;
