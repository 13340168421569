import MapboxDraw from "@mapbox/mapbox-gl-draw";
import type { Feature, LineString } from "geojson";
import { useState, useEffect } from "react";
import useEscapeKeyDown from "../../../../hooks/useEscapeKeyDown";
import { addMapboxDrawControlToMap } from "../../../../utils/addMapboxDrawControlToMap/addMapboxDrawControlToMap";
import { useVisualiser } from "../../../ui/Visualiser/VisualiserProvider";
import useUnsafeMapContext from "../../Map/useUnsafeMapContext";
import LineAnnotationModal from "./LineAnnotationModal";

interface LineAnnotationLayerProps {
  incidentId: string;
}

const drawControl = new MapboxDraw({
  displayControlsDefault: false,
});

const LineAnnotationLayer = ({ incidentId }: LineAnnotationLayerProps) => {
  const { onToolComplete } = useVisualiser();
  const { lib, map } = useUnsafeMapContext();

  const [lineStringFeature, setLineStringFeature] =
    useState<Feature<LineString> | null>(null);

  useEffect(() => {
    if (!map) {
      return;
    }

    // NOTE: This is a mutation function! Adds classes and the control to the map, and returns the cleanup function
    const removeDrawControl = addMapboxDrawControlToMap(drawControl, lib, map);

    map.getCanvas().style.cursor = "crosshair";

    drawControl.changeMode("draw_line_string");

    const onDraw = () => {
      const geojson = drawControl.getAll();
      setLineStringFeature(geojson.features[0] as Feature<LineString>);
    };

    map.on("draw.create", onDraw);

    return () => {
      map.getCanvas().style.cursor = "";
      map.off("draw.create", onDraw);
      removeDrawControl();
    };
  }, [lib, map]);

  const isLineAnnotationModalOpen = !!lineStringFeature;

  const onEscapeKeyDown = () => {
    if (isLineAnnotationModalOpen) return;

    onToolComplete();
  };

  const onClose = () => {
    onToolComplete();
  };

  useEscapeKeyDown(onEscapeKeyDown);

  return (
    <LineAnnotationModal
      incidentId={incidentId}
      isOpen={isLineAnnotationModalOpen}
      onClose={onClose}
      lineStringFeature={lineStringFeature}
    />
  );
};

export default LineAnnotationLayer;
