import { ModalForm, onPromise, showToast } from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import {
  getGetIncidentsIdAnnotationsQueryKey,
  usePostIncidentsIdAnnotations,
} from "../../../../../.rest-hooks/annotations";
import { isLineAnnotation } from "../../IncidentAnnotations/AnnotatedLine/types";
import type { LineStringFeature } from "../../types";
import LineAnnotationForm, {
  getDefaultLineAnnotationFormValues,
  type LineAnnotationFormValues,
} from "./LineAnnotationForm";

interface LineAnnotationModalProps {
  incidentId: string;
  isOpen: boolean;
  onClose: () => void;
  lineStringFeature: LineStringFeature | null;
}

const LineAnnotationModal = ({
  incidentId,
  isOpen,
  onClose,
  lineStringFeature,
}: LineAnnotationModalProps) => {
  const queryClient = useQueryClient();

  const defaultValues = getDefaultLineAnnotationFormValues();

  const form = useForm<LineAnnotationFormValues>({
    defaultValues,
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(getDefaultLineAnnotationFormValues());
  }, [reset]);

  const { mutateAsync: createLineAnnotationMutation } =
    usePostIncidentsIdAnnotations({
      mutation: {
        onSuccess: (data) => {
          const annotation = data.data.data;
          if (!isLineAnnotation(annotation)) {
            return;
          }
          void queryClient.invalidateQueries({
            queryKey: getGetIncidentsIdAnnotationsQueryKey(incidentId),
          });

          showToast({
            variant: "success",
            title: "Note saved",
          });
        },
        onError: () => {
          showToast({
            variant: "error",
            title: "Unable to save note",
          });
        },
      },
    });

  const onSubmit: SubmitHandler<LineAnnotationFormValues> = async (values) => {
    if (!lineStringFeature) {
      return null;
    }

    await createLineAnnotationMutation({
      id: incidentId,
      data: {
        annotationType: "line",
        note: values.note,
        geometry: {
          type: "LineString",
          coordinates: lineStringFeature.geometry.coordinates,
        },
      },
    });

    onClose();
  };

  return (
    <ModalForm
      id="lineAnnotation"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onCancel={onClose}
      onSubmit={onPromise(handleSubmit(onSubmit))}
      submitLabel="Save"
      title="Add note"
    >
      <FormProvider {...form}>
        <LineAnnotationForm />
      </FormProvider>
    </ModalForm>
  );
};

export default LineAnnotationModal;
