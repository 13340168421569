import type { LngLatLike } from "mapbox-gl";
import type { GetPropertiesFromFeatureFn } from "../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isPointFeature } from "../types";

export type HealthFacilitiesFeatureProperties = FeatureInteractionProperties & {
  symbolName: string;
  name: string;
  type: string;
  address: string;
  locality: string;
  phone: string;
};

export type HealthFacilitiesFeatureInteractionState =
  FeatureInteractionState<HealthFacilitiesFeatureProperties>;

export const getPropertiesFromHealthFacilitiesFeature: GetPropertiesFromFeatureFn<
  HealthFacilitiesFeatureProperties
> = (feature, event): HealthFacilitiesFeatureProperties | null => {
  if (!isPointFeature(feature)) {
    return null;
  }

  const lngLat = (feature.geometry.coordinates as LngLatLike) || event.lngLat;

  return {
    featureId: feature.properties.OBJECTID,
    lngLat,
    symbolName: feature.properties.symbolname,
    name: feature.properties.facname,
    type: feature.properties.hfactype,
    address: feature.properties.staddrtag,
    locality: feature.properties.locality,
    phone: feature.properties.phone1,
  };
};

export type HealthCentresFeatureProperties = FeatureInteractionProperties & {
  symbolName: string;
  name: string;
  agency: string;
  function: string;
  locality: string;
};

export type HealthCentresFeatureInteractionState =
  FeatureInteractionState<HealthCentresFeatureProperties>;

export const getPropertiesFromHealthCentresFeature: GetPropertiesFromFeatureFn<
  HealthCentresFeatureProperties
> = (feature, event): HealthCentresFeatureProperties | null => {
  if (!isPointFeature(feature)) {
    return null;
  }

  const lngLat = (feature.geometry.coordinates as LngLatLike) || event.lngLat;

  return {
    featureId: feature.properties.OBJECTID,
    lngLat,
    symbolName: feature.properties.symbolname,
    name: feature.properties.name,
    agency: feature.properties.custodian_agency,
    function: feature.properties.main_function,
    locality: feature.properties.suburb,
  };
};
