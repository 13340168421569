import { Field, FileInput } from "@app/design-system";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";

export interface UploadMediaFormValues {
  mediaFile: File[];
}

export const defaultUploadMediaFormValues: UploadMediaFormValues = {
  mediaFile: [],
};

const StyledAddMediaForm = styled.div`
  display: grid;
`;

const UploadMediaForm = () => {
  const { control } = useFormContext<UploadMediaFormValues>();

  return (
    <StyledAddMediaForm>
      <Controller
        control={control}
        name="mediaFile"
        render={({ field, fieldState: { error } }) => (
          <Field error={error} htmlFor="mediaFile" label="Media">
            <FileInput
              {...field}
              accept={{
                "application/image": [".jpeg", ".jpg", ".png", ".heic"],
              }}
              id="mediaFile"
              validationStatus={error && "error"}
              data-testid={field.name}
            />
          </Field>
        )}
        rules={{
          validate: (value) => !!value.length || "Please choose a media file",
        }}
      />
    </StyledAddMediaForm>
  );
};

export default UploadMediaForm;
