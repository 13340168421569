import { useVisualiser } from "../../../ui/Visualiser/VisualiserProvider";
import PolygonAnnotationLayer from "./PolygonAnnotationLayer";

interface PolygonAnnotationProps {
  incidentId: string;
}

const PolygonAnnotation = ({ incidentId }: PolygonAnnotationProps) => {
  const {
    visualiserState: { activeTool },
  } = useVisualiser();

  if (!activeTool) return null;

  const {
    mode: { id, selection },
  } = activeTool;

  if (id !== "draw-polygon" || !selection) return null;

  return (
    <PolygonAnnotationLayer incidentId={incidentId} patternId={selection} />
  );

  return null;
};

export default PolygonAnnotation;
