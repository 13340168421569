import { Alert, ModalForm, onPromise, showToast } from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import {
  FormProvider,
  type SubmitErrorHandler,
  type SubmitHandler,
  useForm,
} from "react-hook-form";
import {
  getGetAnnotationsIdQueryKey,
  getGetIncidentsIdAnnotationsQueryKey,
  usePatchAnnotationsId,
} from "../../../../.rest-hooks/annotations";
import type { LineAnnotation } from "../../map/IncidentAnnotations/AnnotatedLine/types";
import EditAnnotatedLineForm, {
  type EditAnnotatedLineFormValues,
  getDefaultEditAnnotatedLineFormValues,
} from "./EditAnnotatedLineForm";

interface EditAnnotatedLineModalProps {
  annotation: LineAnnotation;
  incidentId: string;
  isOpen: boolean;
  onClose: () => void;
}

const EditAnnotatedLineModal = ({
  annotation,
  incidentId,
  isOpen,
  onClose,
}: EditAnnotatedLineModalProps) => {
  const queryClient = useQueryClient();
  const [isError, setIsError] = useState<boolean>(false);

  const { mutateAsync: editAnnotatedLineMutation } = usePatchAnnotationsId({
    mutation: {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: getGetAnnotationsIdQueryKey(annotation.id),
        });
        void queryClient.invalidateQueries({
          queryKey: getGetIncidentsIdAnnotationsQueryKey(incidentId),
        });
        showToast({
          variant: "success",
          title: "Note updated",
        });
      },
    },
  });

  const form = useForm<EditAnnotatedLineFormValues>({
    defaultValues: getDefaultEditAnnotatedLineFormValues(annotation),
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(getDefaultEditAnnotatedLineFormValues(annotation));
  }, [annotation, isOpen, reset]);

  const onSubmit: SubmitHandler<EditAnnotatedLineFormValues> = async (
    values,
  ) => {
    setIsError(false);
    try {
      await editAnnotatedLineMutation({
        id: annotation.id,
        data: {
          note: values.note,
        },
      });
      onClose();
    } catch {
      setIsError(true);
    }
  };

  const onSubmitError: SubmitErrorHandler<EditAnnotatedLineFormValues> = () => {
    setIsError(false);
  };

  return (
    <ModalForm
      error={isError && <Alert variant="error" title="Unable to edit note" />}
      id="editLineAnnotation"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onCancel={onClose}
      onSubmit={onPromise(handleSubmit(onSubmit, onSubmitError))}
      submitLabel="Save"
      title="Edit line annotation"
    >
      <FormProvider {...form}>
        <EditAnnotatedLineForm />
      </FormProvider>
    </ModalForm>
  );
};

export default EditAnnotatedLineModal;
