import { Alert } from "@app/design-system";
import { useBoolean } from "usehooks-ts";
import { useGetAnnotationsId } from "../../../../.rest-hooks/annotations";
import { useGetIncidentsId } from "../../../../.rest-hooks/incidents";
import type { AnnotatedPolygonInteractionState } from "../../map/IncidentAnnotations/AnnotatedPolygon/interactions";
import { isPolygonAnnotation } from "../../map/IncidentAnnotations/AnnotatedPolygon/types";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import AnnotationPopupDialog from "../utils/AnnotationPopupDialog";
import DeleteAnnotationModal from "../utils/DeleteAnnotationModal";
import SkeletonAnnotationPopupDialog from "../utils/SkeletonAnnotationPopupDialog";
import EditAnnotatedPolygonModal from "./EditAnnotatedPolygonModal";

interface AnnotatedPolygonPopupProps {
  incidentId: string;
  onClose: () => void;
  state: AnnotatedPolygonInteractionState;
}

const AnnotatedPolygonPopup = ({
  incidentId,
  onClose,
  state,
}: AnnotatedPolygonPopupProps) => {
  const annotationId = state.properties?.data.annotationId ?? "";

  const {
    data: annotationData,
    isError,
    isPending,
  } = useGetAnnotationsId(annotationId, {
    query: {
      enabled: !!annotationId,
    },
  });

  const { data: incidentData } = useGetIncidentsId(incidentId);
  const incident = incidentData?.data.data;

  const {
    setTrue: openDeleteModal,
    setFalse: closeDeleteModal,
    value: isDeleteModalOpen,
  } = useBoolean(false);

  const onDeleteSuccess = () => {
    closeDeleteModal();
    onClose();
  };

  const {
    setTrue: openEditModal,
    setFalse: closeEditModal,
    value: isEditModalOpen,
  } = useBoolean(false);

  let content: React.ReactNode;

  if (isError) {
    content = (
      <MapPopupView header="Polygon annotation" onClose={onClose} hasClose>
        <Alert variant="error" title="Unable to text annotation" />
      </MapPopupView>
    );
  } else if (isPending) {
    content = <SkeletonAnnotationPopupDialog onClose={onClose} />;
  } else {
    const annotation = annotationData.data.data;
    if (!isPolygonAnnotation(annotation)) {
      content = (
        <MapPopupView header="Polygon annotation" onClose={onClose} hasClose>
          <Alert variant="error" title="Invalid annotation" />
        </MapPopupView>
      );
    } else {
      content = (
        <>
          <AnnotationPopupDialog
            annotation={annotation}
            onClose={onClose}
            onDeleteClick={openDeleteModal}
            onEditClick={openEditModal}
            title="Polygon annotation"
          />
          <DeleteAnnotationModal
            annotationId={annotationId}
            incident={incident}
            incidentId={incidentId}
            isOpen={isDeleteModalOpen}
            onCancel={closeDeleteModal}
            onDeleteSuccess={onDeleteSuccess}
          />
          <EditAnnotatedPolygonModal
            annotation={annotation}
            incidentId={incidentId}
            isOpen={isEditModalOpen}
            onClose={closeEditModal}
          />
        </>
      );
    }
  }

  return (
    <SpatialPopup
      isOpen={state.isActive}
      onClose={onClose}
      popupConfig={{
        id: state.id,
        offset: 16,
        size: "lg",
        type: "click",
      }}
      lngLat={state.properties?.lngLat}
    >
      {content}
    </SpatialPopup>
  );
};

export default AnnotatedPolygonPopup;
