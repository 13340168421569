import { uploadMedia } from "../../../config/tools";
import { useVisualiser } from "../../ui/Visualiser/VisualiserProvider";
import UploadMediaLayer from "./UploadMediaLayer";

interface UploadMediaProps {
  incidentId: string;
}

const UploadMedia = ({ incidentId }: UploadMediaProps) => {
  const { isToolActive } = useVisualiser();

  if (!isToolActive(uploadMedia)) {
    return null;
  }

  return <UploadMediaLayer incidentId={incidentId} />;
};

export default UploadMedia;
