import { Text } from "@app/design-system";
import type { GISCorrection } from "../../../../.rest-hooks/types";
import { getLongFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import MapPopupView from "../SpatialPopup/MapPopupView";

interface FlaggedDataHoverPopupViewProps {
  flaggedData: GISCorrection;
  onClose: () => void;
}

const FlaggedDataHoverPopupView = ({
  flaggedData,
  onClose,
}: FlaggedDataHoverPopupViewProps) => {
  return (
    <MapPopupView hasClose={false} onClose={onClose}>
      <Text size="bodyDefaultStrong">{flaggedData.attributes.note}</Text>
      <Text size="bodyDefaultStrong">
        Data layer: {flaggedData.attributes.dataLayer || <>&mdash;</>}
      </Text>
      <Text size="bodyDefaultStrong">
        Author: {flaggedData.attributes.modifiedBy || <>&mdash;</>}
      </Text>
      <Text size="bodyDefaultStrong">
        {getLongFormattedDateAndTime(
          flaggedData.attributes.modifiedAt * 1000,
        ) || <>&mdash;</>}
      </Text>
    </MapPopupView>
  );
};

export default FlaggedDataHoverPopupView;
