import type { Feature, FeatureCollection, Polygon } from "geojson";
import type { Annotation } from "../../../../../.rest-hooks/types";
import type { AnnotationPolygonAttributes } from "../../../../../.rest-hooks/types/annotations.yml";

export type PolygonAnnotation = Annotation & {
  attributes: AnnotationPolygonAttributes;
};

export const isPolygonAnnotation = (
  annotation: Annotation,
): annotation is PolygonAnnotation => {
  return annotation.attributes.annotationType === "polygon";
};

export interface PolygonAnnotationProperties {
  annotationId: string;
  patternId: string;
}

export type PolygonAnnotationFeature = Feature<
  Polygon,
  PolygonAnnotationProperties
>;

export type PolygonAnnotationFeatureCollection = FeatureCollection<
  Polygon,
  PolygonAnnotationProperties
>;

export const isPolygonAnnotationFeature = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  feature: Feature<Polygon, any>,
): feature is PolygonAnnotationFeature => {
  return !!feature.properties?.annotationId;
};
