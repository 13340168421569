import { showToast } from "@app/design-system";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import type { Feature, Polygon } from "geojson";
import { useEffect, useState } from "react";
import useEscapeKeyDown from "../../../../hooks/useEscapeKeyDown";
import { addMapboxDrawControlToMap } from "../../../../utils/addMapboxDrawControlToMap/addMapboxDrawControlToMap";
import { useVisualiser } from "../../../ui/Visualiser/VisualiserProvider";
import useUnsafeMapContext from "../../Map/useUnsafeMapContext";
import PolygonAnnotationModal from "./PolygonAnnotationModal";

interface PolygonAnnotationLayerProps {
  incidentId: string;
  patternId: string;
}

const drawControl = new MapboxDraw({
  displayControlsDefault: false,
});

const PolygonAnnotationLayer = ({
  incidentId,
  patternId,
}: PolygonAnnotationLayerProps) => {
  const { onToolComplete } = useVisualiser();
  const { lib, map } = useUnsafeMapContext();

  const [polygonFeature, setPolygonFeature] = useState<Feature<Polygon> | null>(
    null,
  );

  useEffect(() => {
    if (!map) {
      return;
    }

    // NOTE: This is a mutation function! Adds classes and the control to the map, and returns the cleanup function
    const removeDrawControl = addMapboxDrawControlToMap(drawControl, lib, map);

    map.getCanvas().style.cursor = "crosshair";
    drawControl.changeMode("draw_polygon");

    const onDraw = () => {
      const geojson = drawControl.getAll();
      setPolygonFeature(geojson.features[0] as Feature<Polygon>);
    };

    const validatePolygon = () => {
      const data = drawControl.getAll();
      if (data.features.length === 0) {
        drawControl.changeMode("draw_polygon");
        showToast({
          variant: "error",
          title: "Annotation could not be saved",
          message: "A minimum of 3 points are required to create a polygon",
        });
      }
    };

    map.on("click", validatePolygon);
    map.on("touchend", validatePolygon);
    map.on("draw.create", onDraw);

    return () => {
      map.getCanvas().style.cursor = "";
      map.off("click", validatePolygon);
      map.off("touchend", validatePolygon);
      map.off("draw.create", onDraw);
      removeDrawControl();
    };
  }, [lib, map]);

  const isPolygonAnnotationModalOpen = !!polygonFeature;

  const onEscapeKeyDown = () => {
    if (isPolygonAnnotationModalOpen) return;

    onToolComplete();
  };

  const onClose = () => {
    onToolComplete();
  };

  useEscapeKeyDown(onEscapeKeyDown);

  return (
    <PolygonAnnotationModal
      incidentId={incidentId}
      isOpen={isPolygonAnnotationModalOpen}
      onClose={onClose}
      patternId={patternId}
      polygonFeature={polygonFeature}
    />
  );
};
export default PolygonAnnotationLayer;
