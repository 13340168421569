import type { Feature, FeatureCollection, Point } from "geojson";
import type { Annotation } from "../../../../../.rest-hooks/types";
import type { AnnotationMediaAttributes } from "../../../../../.rest-hooks/types/annotations.yml";

export type MediaAnnotation = Annotation & {
  attributes: AnnotationMediaAttributes;
};

export const isMediaAnnotation = (
  annotation: Annotation,
): annotation is MediaAnnotation => {
  return annotation.attributes.annotationType === "media";
};

export interface MediaAnnotationProperties {
  annotationId: string;
}

export type MediaAnnotationFeature = Feature<Point, MediaAnnotationProperties>;

export type MediaAnnotationFeatureCollection = FeatureCollection<
  Point,
  MediaAnnotationProperties
>;

export const isMediaAnnotationFeature = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  feature: Feature<Point, any>,
): feature is MediaAnnotationFeature => {
  return !!feature.properties?.annotationId;
};
