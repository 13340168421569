import { Checkbox, Field, TextArea } from "@app/design-system";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import type { SymbolAnnotation } from "../../map/IncidentAnnotations/AnnotatedSymbol/types";

const StyledEditAnnotatedSymbolForm = styled.div`
  display: grid;
  gap: 8px;
`;
export interface EditAnnotatedSymbolFormValues {
  confirmed?: boolean;
  note: string;
}

export const getDefaultEditAnnotatedSymbolFormValues = (
  annotation: SymbolAnnotation,
): EditAnnotatedSymbolFormValues => {
  return {
    confirmed: annotation.attributes.confirmed,
    note: annotation.attributes.note,
  };
};

const EditAnnotatedSymbolForm = () => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<EditAnnotatedSymbolFormValues>();

  return (
    <StyledEditAnnotatedSymbolForm>
      <Field error={errors.note} htmlFor="note" label="Note">
        <TextArea
          id="note"
          validationStatus={errors.note && "error"}
          maxLength={300}
          {...register("note", { required: "Please enter a note" })}
        />
      </Field>
      <Controller
        control={control}
        name="confirmed"
        render={({
          field: { onChange, value, ...field },
          fieldState: { error },
        }) => {
          return (
            <Checkbox
              {...field}
              checked={value}
              disabled={value === true}
              error={error}
              label="Confirm annotation"
              onChange={(event) => {
                onChange(event.currentTarget.checked);
              }}
            />
          );
        }}
      />
    </StyledEditAnnotatedSymbolForm>
  );
};

export default EditAnnotatedSymbolForm;
