import { ModalForm, onPromise, showToast } from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import {
  getGetGisCorrectionsIdQueryKey,
  usePatchGisCorrectionsId,
} from "../../../../.rest-hooks/gis-corrections";
import type { GISCorrection } from "../../../../.rest-hooks/types";
import EditFlaggedDataForm, {
  type EditFlaggedDataFormValues,
  getDefaultEditFlaggedDataFormValues,
} from "./EditFlaggedDataForm";

interface EditFlaggedDataModalProps {
  flaggedData: GISCorrection;
  incidentId: string;
  isOpen: boolean;
  onClose: () => void;
}
const EditFlaggedDataModal = ({
  flaggedData,
  incidentId,
  isOpen,
  onClose,
}: EditFlaggedDataModalProps) => {
  const queryClient = useQueryClient();

  const { mutateAsync: editFlaggedDataMutation } = usePatchGisCorrectionsId({
    mutation: {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: getGetGisCorrectionsIdQueryKey(flaggedData.id),
        });
        void queryClient.invalidateQueries({
          queryKey: getGetGisCorrectionsIdQueryKey(incidentId),
        });

        onClose();
        showToast({
          variant: "success",
          title: "Markup updated",
        });
      },
      onError: () => {
        showToast({
          variant: "error",
          title: "Unable to update markup",
        });
      },
    },
  });

  const form = useForm<EditFlaggedDataFormValues>({
    defaultValues: getDefaultEditFlaggedDataFormValues(flaggedData),
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(getDefaultEditFlaggedDataFormValues(flaggedData));
  }, [flaggedData, isOpen, reset]);

  const onEditSubmit: SubmitHandler<EditFlaggedDataFormValues> = (values) => {
    return editFlaggedDataMutation({
      id: flaggedData.id,
      data: {
        confirmed: values.confirmed,
        note: values.note,
      },
    });
  };

  return (
    <ModalForm
      id="editFlaggedData"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onCancel={onClose}
      onSubmit={onPromise(handleSubmit(onEditSubmit))}
      submitLabel="Save"
      title="Edit flagged data"
    >
      <FormProvider {...form}>
        <EditFlaggedDataForm />
      </FormProvider>
    </ModalForm>
  );
};

export default EditFlaggedDataModal;
