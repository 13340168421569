import { showMarkupLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import { IncidentAnnotationsLayer } from "./IncidentAnnotationsLayer";

interface IncidentAnnotationsProps {
  incidentId: string;
}

const IncidentAnnotations = ({ incidentId }: IncidentAnnotationsProps) => {
  const { isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(showMarkupLayer.id)) {
    return null;
  }

  return <IncidentAnnotationsLayer incidentId={incidentId} />;
};

export default IncidentAnnotations;
