import { InverseSkeletonTheme, Skeleton } from "@app/design-system";
import MapPopupView from "../SpatialPopup/MapPopupView";
import { StyledText } from "../utils/AnnotationPopupDialog";

interface SkeletonFlaggedDataHoverPopupViewProps {
  onClose: () => void;
}

const SkeletonFlaggedDataHoverPopupView = ({
  onClose,
}: SkeletonFlaggedDataHoverPopupViewProps) => {
  return (
    <InverseSkeletonTheme>
      <MapPopupView hasClose={false} onClose={onClose}>
        <StyledText>
          <Skeleton />
        </StyledText>
        <StyledText>
          <Skeleton />
        </StyledText>
        <StyledText>
          <Skeleton />
        </StyledText>
        <StyledText>
          <Skeleton />
        </StyledText>
      </MapPopupView>
    </InverseSkeletonTheme>
  );
};

export default SkeletonFlaggedDataHoverPopupView;
