import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";
import {
  publicSafetyNetworkGisLayers,
  type PublicSafetyNetworkSubLayerIdType,
} from "./utils";

interface PublicSafetyNetworkLayerProps {
  opacity?: number;
  subLayerId: PublicSafetyNetworkSubLayerIdType;
}

const PublicSafetyNetworkLayer = ({
  opacity = 1,
  subLayerId,
}: PublicSafetyNetworkLayerProps) => {
  const map = useMapContext();

  useEffect(() => {
    const query = buildMapboxBBoxQuery({
      dpi: "96",
      format: "png32",
      layers: `show:${publicSafetyNetworkGisLayers[subLayerId].join(",")}`,
      transparent: "true",
      bboxSR: "3857",
      imageSR: "3857",
      size: "512,512",
      f: "image",
    });

    map.addSource(subLayerId, {
      type: "raster",
      tileSize: 512,
      tiles: [
        `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/AthenaMobileInfrastructure/MapServer/export?${query}`,
      ],
    });

    map.addLayer(
      {
        id: subLayerId,
        type: "raster",
        source: subLayerId,
      },
      MapLevel.BACKGROUND,
    );

    return () => {
      if (map.getLayer(subLayerId)) {
        map.removeLayer(subLayerId);
      }
      if (map.getSource(subLayerId)) {
        map.removeSource(subLayerId);
      }
    };
  }, [map, subLayerId]);

  useEffect(() => {
    map?.setPaintProperty(subLayerId, "raster-opacity", opacity);
  }, [map, opacity, subLayerId]);

  return null;
};

export default PublicSafetyNetworkLayer;
