import { Checkbox, Field, TextArea, TextInput } from "@app/design-system";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import type { MediaAnnotation } from "../../map/IncidentAnnotations/UploadedMedia/types";

export interface EditMediaFormValues {
  confirmed: boolean;
  fileName: string;
  location: string;
  note: string;
}

export const getDefaultEditMediaFormValues = (
  annotation: MediaAnnotation,
): EditMediaFormValues => {
  return {
    confirmed: annotation.attributes.confirmed,
    fileName: annotation.attributes.media.filename,
    location: annotation.attributes.location ?? "",
    note: annotation.attributes.note,
  };
};

const StyledEditUploadedMediaForm = styled.div`
  display: grid;
  gap: 8px;
`;

const EditUploadedMediaForm = () => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<EditMediaFormValues>();

  return (
    <StyledEditUploadedMediaForm>
      <Field htmlFor="fileName" label="Uploaded Media">
        <TextInput disabled id="fileName" {...register("fileName")} />
      </Field>
      <Field htmlFor="location" label="Location">
        <TextInput id="location" {...register("location")} />
      </Field>
      <Field error={errors.note} htmlFor="note" label="Note">
        <TextArea
          id="note"
          validationStatus={errors.note && "error"}
          {...register("note", { required: "Please enter a note" })}
        />
      </Field>
      <Controller
        control={control}
        name="confirmed"
        render={({ field: { onChange, value, ...field } }) => {
          return (
            <Checkbox
              {...field}
              checked={value}
              label="Mark as confirmed"
              onChange={(event) => {
                onChange(event.currentTarget.checked);
              }}
            />
          );
        }}
      />
    </StyledEditUploadedMediaForm>
  );
};

export default EditUploadedMediaForm;
