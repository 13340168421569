import { useEffect } from "react";
import useMapContext from "../../Map/useMapContext";
import { isGeoJsonSource } from "../../types";
import { ANNOTATED_POLYGON_SOURCE_ID } from "./constants";
import type {
  PolygonAnnotation,
  PolygonAnnotationFeature,
  PolygonAnnotationFeatureCollection,
} from "./types";

interface UseAnnotatedPolygonMapDataParams {
  annotations: PolygonAnnotation[];
}

const useAnnotatedPolygonMapData = ({
  annotations,
}: UseAnnotatedPolygonMapDataParams) => {
  const map = useMapContext();

  useEffect(() => {
    const setData = () => {
      const source = map.getSource(ANNOTATED_POLYGON_SOURCE_ID);
      if (!isGeoJsonSource(source)) {
        return;
      }

      const features = annotations.map<PolygonAnnotationFeature>(
        (polygonAnnotation) => ({
          type: "Feature",
          properties: {
            annotationId: polygonAnnotation.id,
            patternId: polygonAnnotation.attributes.patternId,
            note: polygonAnnotation.attributes.note,
            type: polygonAnnotation.attributes.annotationType,
          },
          geometry: polygonAnnotation.attributes.geometry,
        }),
      );

      const featureCollection: PolygonAnnotationFeatureCollection = {
        type: "FeatureCollection",
        features,
      };

      source.setData(featureCollection);
    };

    setData();
  }, [annotations, map]);
};

export default useAnnotatedPolygonMapData;
