import { useEffect } from "react";
import useMapContext from "../../Map/useMapContext";
import { isGeoJsonSource } from "../../types";
import { ANNOTATED_SYMBOL_SOURCE_ID } from "./constants";
import type {
  SymbolAnnotation,
  SymbolAnnotationFeature,
  SymbolAnnotationFeatureCollection,
} from "./types";

interface UseAnnotatedSymbolMapDataParams {
  annotations: SymbolAnnotation[];
}

const useAnnotatedSymbolMapData = ({
  annotations,
}: UseAnnotatedSymbolMapDataParams) => {
  const map = useMapContext();

  useEffect(() => {
    const setData = () => {
      const source = map.getSource(ANNOTATED_SYMBOL_SOURCE_ID);
      if (!isGeoJsonSource(source)) {
        return;
      }

      const getSymbolName = (symbolAnnotation: SymbolAnnotation) => {
        const symbolName =
          symbolAnnotation.attributes.symbolName.startsWith("symbol") &&
          symbolAnnotation.attributes.confirmed
            ? `confirmed-${symbolAnnotation.attributes.symbolName}`
            : symbolAnnotation.attributes.symbolName;

        return symbolName;
      };

      const features = annotations.map<SymbolAnnotationFeature>(
        (symbolAnnotation) => ({
          type: "Feature",
          properties: {
            annotationId: symbolAnnotation.id,
            confirmed: symbolAnnotation.attributes.confirmed,
            note: symbolAnnotation.attributes.note,
            type: symbolAnnotation.attributes.annotationType,
            symbolName: getSymbolName(symbolAnnotation),
          },
          geometry: symbolAnnotation.attributes.geometry,
        }),
      );

      const featureCollection: SymbolAnnotationFeatureCollection = {
        type: "FeatureCollection",
        features,
      };

      source.setData(featureCollection);
    };

    setData();
  }, [annotations, map]);
};

export default useAnnotatedSymbolMapData;
