import Skeleton from "react-loading-skeleton";
import MapPopupDialog, {
  StyledSkeletonMapPopupDialogButton,
} from "../SpatialPopup/MapPopupDialog";
import { StyledText } from "../utils/AnnotationPopupDialog";

interface SkeletonFlaggedDataPopupDialogProps {
  onClose: () => void;
}

const SkeletonFlaggedDataPopupDialog = ({
  onClose,
}: SkeletonFlaggedDataPopupDialogProps) => {
  return (
    <MapPopupDialog
      footer={
        <>
          <StyledSkeletonMapPopupDialogButton width="80px" />
          <StyledSkeletonMapPopupDialogButton width="80px" />
        </>
      }
      onClose={onClose}
      title={<Skeleton />}
    >
      <StyledText>
        <Skeleton />
      </StyledText>
      <StyledText>
        <Skeleton />
      </StyledText>
      <StyledText>
        <Skeleton />
      </StyledText>
      <StyledText>
        <Skeleton />
      </StyledText>
    </MapPopupDialog>
  );
};

export default SkeletonFlaggedDataPopupDialog;
