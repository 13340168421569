import { useVisualiser } from "../../../ui/Visualiser/VisualiserProvider";
import SymbolAnnotationLayer from "./SymbolAnnotationLayer";

interface SymbolAnnotationProps {
  incidentId: string;
}

const SymbolAnnotation = ({ incidentId }: SymbolAnnotationProps) => {
  const {
    visualiserState: { activeTool },
  } = useVisualiser();

  if (!activeTool) return null;

  const {
    mode: { id, selection },
  } = activeTool;

  if (id !== "place-symbols" || !selection) return null;

  return (
    <SymbolAnnotationLayer incidentId={incidentId} symbolName={selection} />
  );
};

export default SymbolAnnotation;
