import { Draw } from "@app/design-system";
import MapRailDrawerCell from "../MapRailDrawerCell";
import { useMapRailContext } from "../MapRailProvider";
import { createMapRailItem } from "../useMapRail";
import MarkupDrawerView from "./MarkupDrawerView";

const MarkupDrawer = () => {
  const { remove } = useMapRailContext();

  return (
    <MapRailDrawerCell label="Markup" onClose={() => remove({ id: "markup" })}>
      <MarkupDrawerView />
    </MapRailDrawerCell>
  );
};

export const markupMapRailItem = createMapRailItem({
  component: MarkupDrawer,
  icon: Draw,
  iconOn: Draw,
  id: "markup",
  label: "Markup",
});

export default MarkupDrawer;
