import { Field, TextArea } from "@app/design-system";
import { useFormContext } from "react-hook-form";
import type { TextAnnotation } from "../../map/IncidentAnnotations/AnnotatedText/types";

export interface EditAnnotatedTextFormValues {
  note: string;
}

export const getDefaultEditAnnotatedTextFormValues = (
  annotation: TextAnnotation,
): EditAnnotatedTextFormValues => {
  return {
    note: annotation.attributes.note,
  };
};

const EditAnnotatedTextForm = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<EditAnnotatedTextFormValues>();

  return (
    <Field error={errors.note} htmlFor="note" label="Note">
      <TextArea
        id="note"
        validationStatus={errors.note && "error"}
        maxLength={300}
        {...register("note", { required: "Please enter a note" })}
      />
    </Field>
  );
};

export default EditAnnotatedTextForm;
