import { FieldGrid, Text } from "@app/design-system";
import styled from "styled-components";
import { EMDASH } from "../../../lib/strings";
import PreviewSpatialPopup from "../../popup/PreviewSpatialPopup/PreviewSpatialPopup";
import MapPopupView from "../../popup/SpatialPopup/MapPopupView";
import SpatialPopup from "../../popup/SpatialPopup/SpatialPopup";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import { getPropertiesFromHealthFacilitiesFeature } from "./interactions";

const StyledPopupContent = styled.div`
  display: grid;
  gap: 0.5rem;
  text-align: left;
`;

interface HealthFacilitiesPopupProps {
  layerId: string;
}

const HealthFacilitiesPopup = ({ layerId }: HealthFacilitiesPopupProps) => {
  const {
    clickedState,
    hoveredState,
    deactivateHoverState,
    deactivateClickState,
  } = useLayerInteractions({
    getPropertiesFromFeature: getPropertiesFromHealthFacilitiesFeature,
    layerId,
  });

  return (
    <>
      <PreviewSpatialPopup onClose={deactivateHoverState} state={hoveredState}>
        {hoveredState.properties?.name || EMDASH}
      </PreviewSpatialPopup>

      <SpatialPopup
        isOpen={clickedState.isActive}
        lngLat={clickedState.properties?.lngLat}
        onClose={deactivateClickState}
        popupConfig={{
          anchor: "bottom",
          id: clickedState.id,
          offset: 8,
          size: "lg",
          type: "click",
        }}
      >
        <MapPopupView
          hasClose
          header={
            <Text size="eyebrowDefault" variant="weak">
              Health facility
            </Text>
          }
          onClose={deactivateClickState}
        >
          <StyledPopupContent>
            <Text size="subtitleMd">
              {clickedState.properties?.name || EMDASH}
            </Text>
            <FieldGrid>
              <FieldGrid.Item label="Type:">
                {clickedState.properties?.type || EMDASH}
              </FieldGrid.Item>
              <FieldGrid.Item label="Address:">
                {clickedState.properties?.address || EMDASH}
              </FieldGrid.Item>
              <FieldGrid.Item label="Locality:">
                {clickedState.properties?.locality || EMDASH}
              </FieldGrid.Item>
              <FieldGrid.Item label="Phone:">
                {clickedState.properties?.phone || EMDASH}
              </FieldGrid.Item>
            </FieldGrid>
          </StyledPopupContent>
        </MapPopupView>
      </SpatialPopup>
    </>
  );
};

export default HealthFacilitiesPopup;
