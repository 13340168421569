import { ModalForm, onPromise, showToast } from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import type { Position } from "geojson";
import { useEffect } from "react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import {
  getGetIncidentsIdAnnotationsQueryKey,
  usePostIncidentsIdAnnotations,
} from "../../../../../.rest-hooks/annotations";
import { isTextAnnotation } from "../../IncidentAnnotations/AnnotatedText/types";
import TextAnnotationForm, {
  type TextAnnotationFormValues,
  getDefaultTextAnnotationFormValues,
} from "./TextAnnotationForm";

interface TextAnnotationModalProps {
  incidentId: string;
  isOpen: boolean;
  onClose: () => void;
  position: Position | null;
}

const TextAnnotationModal = ({
  incidentId,
  isOpen,
  onClose,
  position,
}: TextAnnotationModalProps) => {
  const queryClient = useQueryClient();

  const defaultValues = getDefaultTextAnnotationFormValues();

  const form = useForm<TextAnnotationFormValues>({
    defaultValues,
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(getDefaultTextAnnotationFormValues());
  }, [reset]);

  const { mutateAsync: createTextAnnotationMutation } =
    usePostIncidentsIdAnnotations({
      mutation: {
        onSuccess: (data) => {
          const annotation = data.data.data;
          if (!isTextAnnotation(annotation)) {
            return;
          }
          void queryClient.invalidateQueries({
            queryKey: getGetIncidentsIdAnnotationsQueryKey(incidentId),
          });

          showToast({
            variant: "success",
            title: "Note saved",
          });
        },
        onError: () => {
          showToast({
            variant: "error",
            title: "Unable to save note",
          });
        },
      },
    });

  const onSubmit: SubmitHandler<TextAnnotationFormValues> = async (values) => {
    if (!position) {
      return null;
    }

    await createTextAnnotationMutation({
      id: incidentId,
      data: {
        annotationType: "text",
        note: values.note,
        geometry: {
          type: "Point",
          coordinates: position,
        },
      },
    });

    onClose();
  };

  return (
    <ModalForm
      id="textAnnotation"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onCancel={onClose}
      onSubmit={onPromise(handleSubmit(onSubmit))}
      submitLabel="Save"
      title="Add note"
    >
      <FormProvider {...form}>
        <TextAnnotationForm />
      </FormProvider>
    </ModalForm>
  );
};

export default TextAnnotationModal;
