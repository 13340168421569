import { Text } from "@app/design-system";
import { getLongFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import type { MediaAnnotation } from "../../map/IncidentAnnotations/UploadedMedia/types";
import MapPopupView from "../SpatialPopup/MapPopupView";

interface UploadedMediaHoverPopupViewProps {
  annotation: MediaAnnotation;
  onClose: () => void;
}

const UploadedMediaHoverPopupView = ({
  annotation,
  onClose,
}: UploadedMediaHoverPopupViewProps) => {
  return (
    <MapPopupView hasClose={false} onClose={onClose}>
      <Text size="bodyDefaultStrong">{annotation.attributes.note}</Text>
      <Text size="bodyDefaultStrong">
        Author: {annotation.attributes.modifiedBy || <>&mdash;</>}
      </Text>
      <Text size="bodyDefaultStrong">
        {getLongFormattedDateAndTime(
          annotation.attributes.modifiedAt * 1000,
        ) || <>&mdash;</>}
      </Text>
    </MapPopupView>
  );
};

export default UploadedMediaHoverPopupView;
