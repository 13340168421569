/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * RFS
 * API documentation for RFS Athena

 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type {
  DeleteGisCorrectionsId200,
  GetGisCorrectionsId200,
  GetIncidentsIdGisCorrections200,
  GetIncidentsIdGisCorrectionsParams,
  PatchGisCorrectionsId200,
  PatchGisCorrectionsIdBody,
  PostIncidentsIdGisCorrections201,
  PostIncidentsIdGisCorrectionsBody,
} from "./types";
import type { JSONAPIErrorResponse } from "./types/jsonapi.yml";

/**
 * @summary Get a GIS correction
 */
export const getGisCorrectionsId = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetGisCorrectionsId200>> => {
  return axios.get(`/gis-corrections/${id}`, options);
};

export const getGetGisCorrectionsIdQueryKey = (id: string) => {
  return [`/gis-corrections/${id}`] as const;
};

export const getGetGisCorrectionsIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getGisCorrectionsId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGisCorrectionsId>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGisCorrectionsIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getGisCorrectionsId>>
  > = ({ signal }) => getGisCorrectionsId(id, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getGisCorrectionsId>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetGisCorrectionsIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGisCorrectionsId>>
>;
export type GetGisCorrectionsIdQueryError = AxiosError<JSONAPIErrorResponse>;

export function useGetGisCorrectionsId<
  TData = Awaited<ReturnType<typeof getGisCorrectionsId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGisCorrectionsId>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGisCorrectionsId>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetGisCorrectionsId<
  TData = Awaited<ReturnType<typeof getGisCorrectionsId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGisCorrectionsId>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGisCorrectionsId>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetGisCorrectionsId<
  TData = Awaited<ReturnType<typeof getGisCorrectionsId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGisCorrectionsId>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a GIS correction
 */

export function useGetGisCorrectionsId<
  TData = Awaited<ReturnType<typeof getGisCorrectionsId>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getGisCorrectionsId>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetGisCorrectionsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Behaves like a restricted `PUT`. The entire request body is required!
 * @summary Update a GIS correction
 */
export const patchGisCorrectionsId = (
  id: string,
  patchGisCorrectionsIdBody: PatchGisCorrectionsIdBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PatchGisCorrectionsId200>> => {
  return axios.patch(
    `/gis-corrections/${id}`,
    patchGisCorrectionsIdBody,
    options,
  );
};

export const getPatchGisCorrectionsIdMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchGisCorrectionsId>>,
    TError,
    { id: string; data: PatchGisCorrectionsIdBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchGisCorrectionsId>>,
  TError,
  { id: string; data: PatchGisCorrectionsIdBody },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchGisCorrectionsId>>,
    { id: string; data: PatchGisCorrectionsIdBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return patchGisCorrectionsId(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchGisCorrectionsIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchGisCorrectionsId>>
>;
export type PatchGisCorrectionsIdMutationBody = PatchGisCorrectionsIdBody;
export type PatchGisCorrectionsIdMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Update a GIS correction
 */
export const usePatchGisCorrectionsId = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchGisCorrectionsId>>,
    TError,
    { id: string; data: PatchGisCorrectionsIdBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchGisCorrectionsId>>,
  TError,
  { id: string; data: PatchGisCorrectionsIdBody },
  TContext
> => {
  const mutationOptions = getPatchGisCorrectionsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete a GIS correction
 */
export const deleteGisCorrectionsId = (
  id: string,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<DeleteGisCorrectionsId200>> => {
  return axios.delete(`/gis-corrections/${id}`, options);
};

export const getDeleteGisCorrectionsIdMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteGisCorrectionsId>>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteGisCorrectionsId>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteGisCorrectionsId>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteGisCorrectionsId(id, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteGisCorrectionsIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteGisCorrectionsId>>
>;

export type DeleteGisCorrectionsIdMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Delete a GIS correction
 */
export const useDeleteGisCorrectionsId = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteGisCorrectionsId>>,
    TError,
    { id: string },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteGisCorrectionsId>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteGisCorrectionsIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Pagination is optional.
 * @summary List GIS corrections for an incident
 */
export const getIncidentsIdGisCorrections = (
  id: string,
  params?: GetIncidentsIdGisCorrectionsParams,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<GetIncidentsIdGisCorrections200>> => {
  return axios.get(`/incidents/${id}/gis-corrections`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetIncidentsIdGisCorrectionsQueryKey = (
  id: string,
  params?: GetIncidentsIdGisCorrectionsParams,
) => {
  return [
    `/incidents/${id}/gis-corrections`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetIncidentsIdGisCorrectionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getIncidentsIdGisCorrections>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params?: GetIncidentsIdGisCorrectionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdGisCorrections>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
) => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetIncidentsIdGisCorrectionsQueryKey(id, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIncidentsIdGisCorrections>>
  > = ({ signal }) =>
    getIncidentsIdGisCorrections(id, params, { signal, ...axiosOptions });

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getIncidentsIdGisCorrections>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetIncidentsIdGisCorrectionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIncidentsIdGisCorrections>>
>;
export type GetIncidentsIdGisCorrectionsQueryError =
  AxiosError<JSONAPIErrorResponse>;

export function useGetIncidentsIdGisCorrections<
  TData = Awaited<ReturnType<typeof getIncidentsIdGisCorrections>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params: undefined | GetIncidentsIdGisCorrectionsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdGisCorrections>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getIncidentsIdGisCorrections>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useGetIncidentsIdGisCorrections<
  TData = Awaited<ReturnType<typeof getIncidentsIdGisCorrections>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params?: GetIncidentsIdGisCorrectionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdGisCorrections>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getIncidentsIdGisCorrections>>,
          TError,
          TData
        >,
        "initialData"
      >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetIncidentsIdGisCorrections<
  TData = Awaited<ReturnType<typeof getIncidentsIdGisCorrections>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params?: GetIncidentsIdGisCorrectionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdGisCorrections>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary List GIS corrections for an incident
 */

export function useGetIncidentsIdGisCorrections<
  TData = Awaited<ReturnType<typeof getIncidentsIdGisCorrections>>,
  TError = AxiosError<JSONAPIErrorResponse>,
>(
  id: string,
  params?: GetIncidentsIdGisCorrectionsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getIncidentsIdGisCorrections>>,
        TError,
        TData
      >
    >;
    axios?: AxiosRequestConfig;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetIncidentsIdGisCorrectionsQueryOptions(
    id,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create a GIS correction
 */
export const postIncidentsIdGisCorrections = (
  id: string,
  postIncidentsIdGisCorrectionsBody: PostIncidentsIdGisCorrectionsBody,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<PostIncidentsIdGisCorrections201>> => {
  return axios.post(
    `/incidents/${id}/gis-corrections`,
    postIncidentsIdGisCorrectionsBody,
    options,
  );
};

export const getPostIncidentsIdGisCorrectionsMutationOptions = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postIncidentsIdGisCorrections>>,
    TError,
    { id: string; data: PostIncidentsIdGisCorrectionsBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postIncidentsIdGisCorrections>>,
  TError,
  { id: string; data: PostIncidentsIdGisCorrectionsBody },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postIncidentsIdGisCorrections>>,
    { id: string; data: PostIncidentsIdGisCorrectionsBody }
  > = (props) => {
    const { id, data } = props ?? {};

    return postIncidentsIdGisCorrections(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostIncidentsIdGisCorrectionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postIncidentsIdGisCorrections>>
>;
export type PostIncidentsIdGisCorrectionsMutationBody =
  PostIncidentsIdGisCorrectionsBody;
export type PostIncidentsIdGisCorrectionsMutationError =
  AxiosError<JSONAPIErrorResponse>;

/**
 * @summary Create a GIS correction
 */
export const usePostIncidentsIdGisCorrections = <
  TError = AxiosError<JSONAPIErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postIncidentsIdGisCorrections>>,
    TError,
    { id: string; data: PostIncidentsIdGisCorrectionsBody },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationResult<
  Awaited<ReturnType<typeof postIncidentsIdGisCorrections>>,
  TError,
  { id: string; data: PostIncidentsIdGisCorrectionsBody },
  TContext
> => {
  const mutationOptions =
    getPostIncidentsIdGisCorrectionsMutationOptions(options);

  return useMutation(mutationOptions);
};
