import type { LngLatLike } from "mapbox-gl";
import type { GetPropertiesFromFeatureFn } from "../../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../../MapInteractions/types";
import { isPointFeature } from "../../types";
import {
  isTextAnnotationFeature,
  type TextAnnotationProperties,
} from "./types";

type AnnotatedTextInteractionProperties = FeatureInteractionProperties & {
  data: TextAnnotationProperties;
};

export type AnnotatedTextInteractionState =
  FeatureInteractionState<AnnotatedTextInteractionProperties>;

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  AnnotatedTextInteractionProperties
> = (feature) => {
  if (!isPointFeature(feature) || !isTextAnnotationFeature(feature)) {
    return null;
  }

  const lngLat = feature.geometry.coordinates as LngLatLike;
  return {
    data: {
      annotationId: feature.properties.annotationId,
    },
    featureId: feature.properties.annotationId,
    lngLat,
  };
};
