import { ModalForm, onPromise, showToast } from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import type { Position } from "geojson";
import React, { useEffect } from "react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import {
  getGetIncidentsIdAnnotationsQueryKey,
  usePostIncidentsIdAnnotations,
} from "../../../../.rest-hooks/annotations";
import type { FileUploadResult } from "../../../hooks/useUploadFile";
import { isMediaAnnotation } from "../IncidentAnnotations/UploadedMedia/types";
import CreateMediaAnnotationForm, {
  type CreateMediaAnnotationFormValues,
  getDefaultCreateMediaAnnotationFormValues,
} from "./CreateMediaAnnotationForm";

interface CreateMediaAnnotationModalProps {
  fileUploadResult: FileUploadResult | null;
  incidentId: string;
  isOpen: boolean;
  onClose: () => void;
  position: Position | null;
}

const CreateMediaAnnotationModal = ({
  fileUploadResult,
  incidentId,
  isOpen,
  onClose,
  position,
}: CreateMediaAnnotationModalProps) => {
  const queryClient = useQueryClient();

  const defaultValues = getDefaultCreateMediaAnnotationFormValues({
    fileUploadResult,
    position,
  });

  const form = useForm<CreateMediaAnnotationFormValues>({
    defaultValues,
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(
      getDefaultCreateMediaAnnotationFormValues({
        fileUploadResult,
        position,
      }),
    );
  }, [fileUploadResult, position, reset]);

  const { mutateAsync: createMediaAnnotationMutation } =
    usePostIncidentsIdAnnotations({
      mutation: {
        onSuccess: (data) => {
          const annotation = data.data.data;
          if (!isMediaAnnotation(annotation)) {
            return;
          }
          void queryClient.invalidateQueries({
            queryKey: getGetIncidentsIdAnnotationsQueryKey(incidentId),
          });

          showToast({
            variant: "success",
            title: "Media saved",
            message: annotation.attributes.media.filename,
          });
        },
        onError: () => {
          showToast({
            variant: "error",
            title: "Media not saved",
            message: "Media upload failed",
          });
        },
      },
    });

  const onSubmit: SubmitHandler<CreateMediaAnnotationFormValues> = async (
    values,
  ) => {
    if (!position || !fileUploadResult) {
      return null;
    }

    const { s3Uri, filename } = fileUploadResult;

    await createMediaAnnotationMutation({
      id: incidentId,
      data: {
        annotationType: "media",
        confirmed: values.confirmed,
        ...(values.location !== defaultValues.location && {
          location: values.location,
        }),
        media: { fileUri: s3Uri, filename },
        note: values.note,
        geometry: {
          type: "Point",
          coordinates: position,
        },
      },
    });

    onClose();
  };

  return (
    <ModalForm
      id="createMediaAnnotation"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onCancel={onClose}
      onSubmit={onPromise(handleSubmit(onSubmit))}
      submitLabel="Save"
      title="Upload media"
    >
      <FormProvider {...form}>
        <CreateMediaAnnotationForm />
      </FormProvider>
    </ModalForm>
  );
};

export default CreateMediaAnnotationModal;
