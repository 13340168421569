import { Alert } from "@app/design-system";
import { useGetGisCorrectionsId } from "../../../../.rest-hooks/gis-corrections";
import type { FlaggedDataInteractionState } from "../../map/IncidentAnnotations/FlaggedData/interactions";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import FlaggedDataHoverPopupView from "./FlaggedDataHoverPopupView";
import SkeletonFlaggedDataHoverPopupView from "./SkeletonFlaggedDataHoverPopupView";

interface FlaggedDataHoverPopupProps {
  onClose: () => void;
  state: FlaggedDataInteractionState;
}

const FlaggedDataHoverPopup = ({
  onClose,
  state,
}: FlaggedDataHoverPopupProps) => {
  const flaggedDataId = state.properties?.data.flaggedDataId ?? "";

  const {
    data: flaggedData,
    isError,
    isPending,
  } = useGetGisCorrectionsId(flaggedDataId);

  let content: React.ReactNode;

  if (isError) {
    content = (
      <MapPopupView header="Flagged data" onClose={onClose} hasClose>
        <Alert variant="error" title="Unable to load flag" />
      </MapPopupView>
    );
  } else if (isPending) {
    content = <SkeletonFlaggedDataHoverPopupView onClose={onClose} />;
  } else {
    const flaggedDataContents = flaggedData.data.data;

    if (!flaggedDataContents) {
      content = (
        <MapPopupView header="Flagged data" onClose={onClose} hasClose>
          <Alert variant="error" title="Invalid annotation" />
        </MapPopupView>
      );
    } else {
      content = (
        <FlaggedDataHoverPopupView
          flaggedData={flaggedDataContents}
          onClose={onClose}
        />
      );
    }
  }

  return (
    <SpatialPopup
      isOpen={state.isActive}
      onClose={onClose}
      popupConfig={{
        id: state.id,
        offset: 16,
        size: "lg",
        type: "hover",
        variant: "inverse",
      }}
      lngLat={state.properties?.lngLat}
    >
      {content}
    </SpatialPopup>
  );
};

export default FlaggedDataHoverPopup;
