import { useEffect } from "react";
import { MapLevel } from "../../../../config/layers/layers";
import catchAbortError from "../../../../utils/catchAbortError/catchAbortError";
import useMapContext from "../../Map/useMapContext";
import { loadImage } from "../../utils/loadImage";
import {
  ANNOTATED_POLYGON_LAYER_ID,
  ANNOTATED_POLYGON_LAYER_OUTLINE_ID,
  ANNOTATED_POLYGON_SOURCE_ID,
  ANNOTATED_POLYGON_TEXT_LAYER_ID,
  FIRE_MAPPING_PATTERNS,
  FIRE_MAPPING_PATTERNS_OUTLINES_MAP,
} from "./constants";

const useAnnotatedPolygonMapLayers = () => {
  const map = useMapContext();

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(ANNOTATED_POLYGON_SOURCE_ID, {
      type: "geojson",
      data: {
        features: [],
        type: "FeatureCollection",
      },
    });

    Promise.all(
      Object.entries(FIRE_MAPPING_PATTERNS).map(([imageId, src]) =>
        loadImage({ imageId, map, src, signal: controller.signal }),
      ),
    ).then(() => {
      map.addLayer(
        {
          id: ANNOTATED_POLYGON_LAYER_ID,
          type: "fill",
          source: ANNOTATED_POLYGON_SOURCE_ID,
          paint: {
            "fill-pattern": ["get", "patternId"],
            "fill-color": "#000",
          },
        },
        MapLevel.FS_LABELS,
      );

      map.addLayer(
        {
          id: ANNOTATED_POLYGON_LAYER_OUTLINE_ID,
          type: "line",
          source: ANNOTATED_POLYGON_SOURCE_ID,
          paint: {
            "line-color": [
              "match",
              ["get", "patternId"],
              ...FIRE_MAPPING_PATTERNS_OUTLINES_MAP,
            ],
            "line-width": 3,
          },
        },
        MapLevel.FS_LABELS,
      );

      map.addLayer(
        {
          id: ANNOTATED_POLYGON_TEXT_LAYER_ID,
          type: "symbol",
          source: ANNOTATED_POLYGON_SOURCE_ID,
          layout: {
            "text-field": ["get", "note"],
            "text-size": ["interpolate", ["linear"], ["zoom"], 0, 12, 16, 22],
            "text-anchor": "center",
            "symbol-placement": "line",
            "text-transform": "uppercase",
            "symbol-spacing": 500,
          },
          paint: {
            "text-color": "red",
            "text-halo-color": "#ffffff",
            "text-halo-width": 10,
          },
        },
        MapLevel.FS_LABELS,
      );
    }, catchAbortError);

    return () => {
      controller.abort();
      if (map.getLayer(ANNOTATED_POLYGON_TEXT_LAYER_ID)) {
        map.removeLayer(ANNOTATED_POLYGON_TEXT_LAYER_ID);
      }
      if (map.getLayer(ANNOTATED_POLYGON_LAYER_ID)) {
        map.removeLayer(ANNOTATED_POLYGON_LAYER_ID);
      }
      if (map.getLayer(ANNOTATED_POLYGON_LAYER_OUTLINE_ID)) {
        map.removeLayer(ANNOTATED_POLYGON_LAYER_OUTLINE_ID);
      }
      if (map.getSource(ANNOTATED_POLYGON_SOURCE_ID)) {
        map.removeSource(ANNOTATED_POLYGON_SOURCE_ID);
      }
    };
  }, [map]);
};

export default useAnnotatedPolygonMapLayers;
