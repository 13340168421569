import { Alert } from "@app/design-system";
import { useBoolean } from "usehooks-ts";
import { useGetGisCorrectionsId } from "../../../../.rest-hooks/gis-corrections";
import { useGetIncidentsId } from "../../../../.rest-hooks/incidents";
import type { FlaggedDataInteractionState } from "../../map/IncidentAnnotations/FlaggedData/interactions";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import DeleteFlaggedDataModal from "./DeleteFlaggedDataModal";
import EditFlaggedDataModal from "./EditFlaggedDataModal";
import FlaggedDataPopupDialog from "./FlaggedDataPopupDialog";
import SkeletonFlaggedDataPopupDialog from "./SkeletonFlaggedDataPopupDialog";

interface FlaggedDataPopupProps {
  incidentId: string;
  onClose: () => void;
  state: FlaggedDataInteractionState;
}

const FlaggedDataPopup = ({
  incidentId,
  onClose,
  state,
}: FlaggedDataPopupProps) => {
  const flaggedDataId = state.properties?.data.flaggedDataId ?? "";

  const {
    data: flaggedData,
    isError,
    isPending,
  } = useGetGisCorrectionsId(flaggedDataId);

  const { data: incidentData } = useGetIncidentsId(incidentId);
  const incident = incidentData?.data.data;

  const {
    setTrue: openDeleteModal,
    setFalse: closeDeleteModal,
    value: isDeleteModalOpen,
  } = useBoolean(false);

  const onDeleteSuccess = () => {
    closeDeleteModal();
    onClose();
  };

  const {
    setTrue: openEditModal,
    setFalse: closeEditModal,
    value: isEditModalOpen,
  } = useBoolean(false);

  let content: React.ReactNode;

  if (isError) {
    content = (
      <MapPopupView header="Flagged data" onClose={onClose} hasClose>
        <Alert variant="error" title="Unable to load flag" />
      </MapPopupView>
    );
  } else if (isPending) {
    content = <SkeletonFlaggedDataPopupDialog onClose={onClose} />;
  } else {
    const flaggedDataContents = flaggedData.data.data;

    if (!flaggedDataContents) {
      content = (
        <MapPopupView header="Flagged data" onClose={onClose} hasClose>
          <Alert variant="error" title="Invalid annotation" />
        </MapPopupView>
      );
    } else {
      content = (
        <>
          <FlaggedDataPopupDialog
            flaggedData={flaggedDataContents}
            onClose={onClose}
            onDeleteClick={openDeleteModal}
            onEditClick={openEditModal}
          />
          <DeleteFlaggedDataModal
            flaggedDataId={flaggedDataId}
            incident={incident}
            incidentId={incidentId}
            isOpen={isDeleteModalOpen}
            onCancel={closeDeleteModal}
            onDeleteSuccess={onDeleteSuccess}
          />
          <EditFlaggedDataModal
            flaggedData={flaggedDataContents}
            incidentId={incidentId}
            isOpen={isEditModalOpen}
            onClose={closeEditModal}
          />
        </>
      );
    }
  }

  return (
    <SpatialPopup
      isOpen={state.isActive}
      onClose={onClose}
      popupConfig={{
        id: state.id,
        offset: 16,
        size: "lg",
        type: "click",
      }}
      lngLat={state.properties?.lngLat}
    >
      {content}
    </SpatialPopup>
  );
};

export default FlaggedDataPopup;
