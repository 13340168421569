import { InverseSkeletonTheme, Text } from "@app/design-system";
import Skeleton from "react-loading-skeleton";
import MapPopupView from "../SpatialPopup/MapPopupDialog";

interface SkeletonUploadedMediaHoverPopupViewProps {
  onClose: () => void;
}

const SkeletonUploadedMediaHoverPopupView = ({
  onClose,
}: SkeletonUploadedMediaHoverPopupViewProps) => {
  return (
    <InverseSkeletonTheme>
      <MapPopupView onClose={onClose}>
        <Text size="bodyDefault">
          <Skeleton />
        </Text>
        <Text size="bodyDefault">
          <Skeleton />
        </Text>
        <Text size="bodyDefault">
          <Skeleton />
        </Text>
      </MapPopupView>
    </InverseSkeletonTheme>
  );
};

export default SkeletonUploadedMediaHoverPopupView;
