import {
  Checkbox,
  type FormControlOption,
  Field,
  Select,
  TextArea,
} from "@app/design-system";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { DATA_LAYER_OPTIONS } from "./DataLayers";

export interface FlagDataFormValues {
  confirmed: boolean;
  dataLayer: FormControlOption | null;
  note: string;
}

export const defaultFlagDataFormValues: FlagDataFormValues = {
  confirmed: false,
  dataLayer: null,
  note: "",
};

export const getDefaultFlagDataFormValues = (): FlagDataFormValues => {
  return {
    confirmed: false,
    dataLayer: null,
    note: "",
  };
};

const StyledFlagDataForm = styled.div`
  display: grid;
  gap: 12px;
`;

const FlagDataForm = () => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<FlagDataFormValues>();

  return (
    <StyledFlagDataForm>
      <Field error={errors.note} htmlFor="note" label="Note">
        <TextArea
          autoFocus
          id="note"
          placeholder="Add information about your note here"
          {...register("note", { required: "Please enter a note" })}
        />
      </Field>
      <Controller
        control={control}
        name="dataLayer"
        rules={{
          validate: (value) =>
            !!value === true || "Please pick a data layer or other",
        }}
        render={({ field, fieldState }) => (
          <Select
            error={fieldState.error}
            label="Data layer"
            options={DATA_LAYER_OPTIONS}
            placeholder="Select data layer"
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="confirmed"
        render={({ field: { onChange, value, ...field } }) => {
          return (
            <Checkbox
              {...field}
              checked={value}
              disabled
              label="Mark as confirmed"
              onChange={(event) => {
                onChange(event.currentTarget.checked);
              }}
            />
          );
        }}
      />
    </StyledFlagDataForm>
  );
};

export default FlagDataForm;
