import { lineAnnotation } from "../../../../config/tools";
import { useVisualiser } from "../../../ui/Visualiser/VisualiserProvider";
import LineAnnotationLayer from "./LineAnnotationLayer";

interface LineAnnotationProps {
  incidentId: string;
}

const LineAnnotation = ({ incidentId }: LineAnnotationProps) => {
  const { isToolActive } = useVisualiser();

  if (!isToolActive(lineAnnotation)) {
    return null;
  }

  return <LineAnnotationLayer incidentId={incidentId} />;
};

export default LineAnnotation;
