import { Text } from "@app/design-system";
import type { Annotation } from "../../../../.rest-hooks/types";
import { getLongFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import MapPopupView from "../SpatialPopup/MapPopupView";

interface AnnotationHoverPopupViewProps {
  annotation: Annotation;
  onClose: () => void;
}

const AnnotationHoverPopupView = ({
  annotation,
  onClose,
}: AnnotationHoverPopupViewProps) => {
  return (
    <MapPopupView hasClose={false} onClose={onClose}>
      <Text size="bodyDefaultStrong">{annotation.attributes.note}</Text>
      <Text size="bodyDefaultStrong">
        Author: {annotation.attributes.modifiedBy || <>&mdash;</>}
      </Text>
      <Text size="bodyDefaultStrong">
        {getLongFormattedDateAndTime(
          annotation.attributes.modifiedAt * 1000,
        ) || <>&mdash;</>}
      </Text>
    </MapPopupView>
  );
};

export default AnnotationHoverPopupView;
