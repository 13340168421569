import type { GetPropertiesFromFeatureFn } from "../../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../../MapInteractions/types";
import { isPolygonFeature } from "../../types";
import {
  isPolygonAnnotationFeature,
  type PolygonAnnotationProperties,
} from "./types";

type AnnotatedPolygonInteractionProperties = FeatureInteractionProperties & {
  data: PolygonAnnotationProperties;
};

export type AnnotatedPolygonInteractionState =
  FeatureInteractionState<AnnotatedPolygonInteractionProperties>;

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  AnnotatedPolygonInteractionProperties
> = (feature, event) => {
  if (!isPolygonFeature(feature) || !isPolygonAnnotationFeature(feature)) {
    return null;
  }

  const { lngLat } = event;

  return {
    data: {
      annotationId: feature.properties?.annotationId,
      patternId: feature.properties?.patternId,
    },
    featureId: feature.properties.annotationId,
    lngLat,
  };
};
