import { showToast } from "@app/design-system";
import { useEffect } from "react";
import { useGetIncidentsIdGisCorrections } from "../../../../../.rest-hooks/gis-corrections";
import FlaggedDataLayer from "./FlaggedDataLayer";

interface FlaggedDataProps {
  incidentId: string;
}

const FlaggedData = ({ incidentId }: FlaggedDataProps) => {
  const { data, isError } = useGetIncidentsIdGisCorrections(incidentId);
  const flaggedData = data?.data.data;

  useEffect(() => {
    if (!isError) return;

    showToast({
      variant: "error",
      title: "Unable to retrieve flagged data",
    });
  }, [isError]);

  if (!flaggedData) {
    return null;
  }

  return <FlaggedDataLayer flaggedData={flaggedData} incidentId={incidentId} />;
};

export default FlaggedData;
