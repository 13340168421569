import type { Position } from "geojson";
import { useState, useEffect } from "react";
import useEscapeKeyDown from "../../../../hooks/useEscapeKeyDown";
import { useVisualiser } from "../../../ui/Visualiser/VisualiserProvider";
import useMapContext from "../../Map/useMapContext";
import type { MapboxMouseEvent } from "../../types";
import TextAnnotationModal from "./TextAnnotationModal";

interface TextAnnotationLayerProps {
  incidentId: string;
}

const TextAnnotationLayer = ({ incidentId }: TextAnnotationLayerProps) => {
  const { onToolComplete } = useVisualiser();
  const map = useMapContext();

  const [position, setPosition] = useState<Position | null>(null);

  useEffect(() => {
    map.getCanvas().style.cursor = "crosshair";

    const onClick = (event: MapboxMouseEvent) => {
      const { lng, lat } = event.lngLat.wrap();
      setPosition([lng, lat]);
    };

    map.on("click", onClick);

    return () => {
      map.getCanvas().style.cursor = "";
      map.off("click", onClick);
    };
  }, [map]);

  const onEscapeKeyDown = () => {
    if (position) return;

    onToolComplete();
  };

  const onClose = () => {
    onToolComplete();
  };

  useEscapeKeyDown(onEscapeKeyDown);

  const isTextAnnotationModalOpen = !!position;

  return (
    <TextAnnotationModal
      incidentId={incidentId}
      isOpen={isTextAnnotationModalOpen}
      onClose={onClose}
      position={position}
    />
  );
};

export default TextAnnotationLayer;
