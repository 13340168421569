import { Field, TextArea } from "@app/design-system";
import { useFormContext } from "react-hook-form";

export interface TextAnnotationFormValues {
  note: string;
}

export const defaultTextAnnotationFormValues: TextAnnotationFormValues = {
  note: "",
};

export const getDefaultTextAnnotationFormValues =
  (): TextAnnotationFormValues => {
    return {
      note: "",
    };
  };

const TextAnnotationForm = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<TextAnnotationFormValues>();

  return (
    <Field error={errors.note} htmlFor="note" label="Note or label">
      <TextArea
        autoFocus
        id="note"
        placeholder="Information about your note or label"
        maxLength={300}
        {...register("note", { required: "Please enter a note" })}
      />
    </Field>
  );
};

export default TextAnnotationForm;
