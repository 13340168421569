import { flagData } from "../../../config/tools";
import { useVisualiser } from "../../ui/Visualiser/VisualiserProvider";
import FlagDataLayer from "./FlagDataLayer";

interface FlagDataProps {
  incidentId: string;
}

const FlagData = ({ incidentId }: FlagDataProps) => {
  const { isToolActive } = useVisualiser();

  if (!isToolActive(flagData)) {
    return null;
  }

  return <FlagDataLayer incidentId={incidentId} />;
};

export default FlagData;
