import type { LngLatLike } from "mapbox-gl";
import type { GetPropertiesFromFeatureFn } from "../../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../../MapInteractions/types";
import { isPointFeature } from "../../types";
import { isFlaggedDataFeature, type FlaggedDataProperties } from "./types";

type FlaggedDataInteractionProperties = FeatureInteractionProperties & {
  data: FlaggedDataProperties;
};

export type FlaggedDataInteractionState =
  FeatureInteractionState<FlaggedDataInteractionProperties>;

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  FlaggedDataInteractionProperties
> = (feature) => {
  if (!isPointFeature(feature) || !isFlaggedDataFeature(feature)) {
    return null;
  }

  const lngLat = feature.geometry.coordinates as LngLatLike;
  return {
    data: {
      flaggedDataId: feature.properties.flaggedDataId,
    },
    featureId: feature.properties.flaggedDataId,
    lngLat,
  };
};
