import { useEffect } from "react";
import { MapLevel } from "../../../../config/layers/layers";
import useMapContext from "../../Map/useMapContext";
import { FLAGGED_DATA_LAYER_ID, FLAGGED_DATA_SOURCE_ID } from "./constants";

const useFlaggedDataMapLayers = () => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(FLAGGED_DATA_SOURCE_ID, {
      type: "geojson",
      data: {
        features: [],
        type: "FeatureCollection",
      },
    });

    map.addLayer(
      {
        id: FLAGGED_DATA_LAYER_ID,
        type: "symbol",
        source: FLAGGED_DATA_SOURCE_ID,
        layout: {
          "icon-image": "flaggedData",
          "icon-allow-overlap": true,
        },
      },
      MapLevel.FS_SYMBOLS,
    );

    return () => {
      if (map.getLayer(FLAGGED_DATA_LAYER_ID)) {
        map.removeLayer(FLAGGED_DATA_LAYER_ID);
      }
      if (map.getSource(FLAGGED_DATA_SOURCE_ID)) {
        map.removeSource(FLAGGED_DATA_SOURCE_ID);
      }
    };
  }, [map]);
};

export default useFlaggedDataMapLayers;
