import { useEffect } from "react";
import type { GISCorrection } from "../../../../../.rest-hooks/types";
import useMapContext from "../../Map/useMapContext";
import { isGeoJsonSource } from "../../types";
import { loadImage } from "../../utils/loadImage";
import { FLAGGED_DATA_SOURCE_ID } from "./constants";
import type { FlaggedDataFeature, FlaggedDataFeatureCollection } from "./types";

interface UseFlaggedDataMapDataParams {
  flaggedData: GISCorrection[];
}

const useFlaggedDataMapData = ({
  flaggedData,
}: UseFlaggedDataMapDataParams) => {
  const map = useMapContext();

  useEffect(() => {
    const controller = new AbortController();

    const loadIconsAndSetData = async () => {
      await loadImage({
        imageId: "flaggedData",
        map,
        pixelRatio: 4,
        src: "/icons/flag.png",
      });

      if (controller.signal.aborted) return;

      const source = map.getSource(FLAGGED_DATA_SOURCE_ID);
      if (!isGeoJsonSource(source)) {
        return;
      }

      const features = flaggedData.map<FlaggedDataFeature>((flagData) => ({
        type: "Feature",
        properties: {
          confirmed: flagData.attributes.confirmed,
          dataLayer: flagData.attributes.dataLayer,
          flaggedDataId: flagData.id,
          note: flagData.attributes.note,
        },
        geometry: flagData.attributes.point,
      }));

      const featureCollection: FlaggedDataFeatureCollection = {
        type: "FeatureCollection",
        features,
      };

      source.setData(featureCollection);
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadIconsAndSetData();

    return () => controller.abort();
  }, [flaggedData, map]);
};

export default useFlaggedDataMapData;
