import type { LngLatLike } from "mapbox-gl";
import type { GetPropertiesFromFeatureFn } from "../MapInteractions/MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  FeatureInteractionState,
} from "../MapInteractions/types";
import { isPointFeature } from "../types";

export type AgedCareFeatureProperties = FeatureInteractionProperties & {
  symbolName: string;
  name: string;
  type: string;
  address: string;
  locality: string;
  phone: string;
};

export type AgedCareFeatureInteractionState =
  FeatureInteractionState<AgedCareFeatureProperties>;

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  AgedCareFeatureProperties
> = (feature, event): AgedCareFeatureProperties | null => {
  if (!isPointFeature(feature)) {
    return null;
  }

  const lngLat = (feature.geometry.coordinates as LngLatLike) || event.lngLat;

  return {
    featureId: feature.properties.OBJECTID,
    lngLat,
    symbolName: feature.properties.symbolname,
    name: feature.properties.facname,
    type: feature.properties.hfactype,
    address: feature.properties.staddrtag,
    locality: feature.properties.locality,
    phone: feature.properties.phone1,
  };
};
