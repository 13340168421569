import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import MapPopupDialog, {
  StyledSkeletonMapPopupDialogButton,
} from "../SpatialPopup/MapPopupDialog";
import { StyledText } from "../utils/AnnotationPopupDialog";
import { StyledAttributes, StyledFigure } from "./UploadedMediaPopupDialog";

const StyledMediaSkeleton = styled(Skeleton)`
  font-size: 200px;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
`;

interface SkeletonUploadedMediaPopupDialogProps {
  onClose: () => void;
}

const SkeletonUploadedMediaPopupDialog = ({
  onClose,
}: SkeletonUploadedMediaPopupDialogProps) => {
  return (
    <MapPopupDialog
      footer={
        <>
          <StyledSkeletonMapPopupDialogButton width="80px" />
          <StyledSkeletonMapPopupDialogButton width="80px" />
        </>
      }
      onClose={onClose}
      title={<Skeleton />}
    >
      <StyledAttributes>
        <StyledText>
          <Skeleton />
        </StyledText>
        <div>
          <StyledText>
            <Skeleton />
          </StyledText>
          <StyledText>
            <Skeleton />
          </StyledText>
          <StyledText>
            <Skeleton />
          </StyledText>
        </div>
        <StyledFigure>
          <StyledMediaSkeleton />
          <StyledText>
            <Skeleton />
          </StyledText>
        </StyledFigure>
      </StyledAttributes>
    </MapPopupDialog>
  );
};

export default SkeletonUploadedMediaPopupDialog;
