export const ANNOTATED_SYMBOL_LAYER_ID = "annotation-symbols";
export const ANNOTATED_SYMBOL_SOURCE_ID = "annotation-symbols";

export const ANNOTATED_SYMBOLS = {
  "firemap-asset-empty": "/icons/firemap-asset-empty.png",
  "firemap-asset-fauna": "/icons/firemap-asset-fauna.png",
  "firemap-asset-flora": "/icons/firemap-asset-flora.png",
  "firemap-asset-historic-site": "/icons/firemap-asset-historic-site.png",
  "firemap-asset-indigenous-site": "/icons/firemap-asset-indigenous-site.png",
  "firemap-asset-threatened": "/icons/firemap-asset-threatened.png",
  "firemap-asset-hazmat": "/icons/firemap-asset-hazmat.png",
  "firemap-asset-defendable": "/icons/firemap-asset-defendable.png",
  "firemap-asset-not-defendable": "/icons/firemap-asset-not-defendable.png",
  "firemap-asset-potentially-defendable":
    "/icons/firemap-asset-potentially-defendable.png",
  "symbol-access-point": "/icons/unconfirmed-firemap-symbol-access-point.png",
  "confirmed-symbol-access-point": "/icons/firemap-symbol-access-point.png",
  "firemap-symbol-airbase": "/icons/firemap-symbol-airbase.png",
  "symbol-ambulance-location":
    "/icons/unconfirmed-firemap-symbol-ambulance-location.png",
  "confirmed-symbol-ambulance-location":
    "/icons/firemap-symbol-ambulance-location.png",
  "symbol-assembly-area": "/icons/unconfirmed-firemap-symbol-assembly-area.png",
  "confirmed-symbol-assembly-area": "/icons/firemap-symbol-assembly-area.png",
  "symbol-base-camp": "/icons/unconfirmed-firemap-symbol-base-camp.png",
  "confirmed-symbol-base-camp": "/icons/firemap-symbol-base-camp.png",
  "firemap-symbol-communications": "/icons/firemap-symbol-communications.png",
  "symbol-control-centre":
    "/icons/unconfirmed-firemap-symbol-control-centre.png",
  "confirmed-symbol-control-centre": "/icons/firemap-symbol-control-centre.png",
  "symbol-control-point": "/icons/unconfirmed-firemap-symbol-control-point.png",
  "confirmed-symbol-control-point": "/icons/firemap-symbol-control-point.png",
  "firemap-symbol-divisional-command-point":
    "/icons/firemap-symbol-divisional-command-point.png",
  "symbol-evacuation-area":
    "/icons/unconfirmed-firemap-symbol-evacuation-area.png",
  "confirmed-symbol-evacuation-area":
    "/icons/firemap-symbol-evacuation-area.png",
  "symbol-evacuation-centre":
    "/icons/unconfirmed-firemap-symbol-evacuation-centre.png",
  "confirmed-symbol-evacuation-centre":
    "/icons/firemap-symbol-evacuation-centre.png",
  "symbol-escape-route": "/icons/unconfirmed-firemap-symbol-escape-route.png",
  "confirmed-symbol-escape-route": "/icons/firemap-symbol-escape-route.png",
  "firemap-symbol-fire-vehicle": "/icons/firemap-symbol-fire-vehicle.png",
  "symbol-helibase": "/icons/unconfirmed-firemap-symbol-helibase.png",
  "confirmed-symbol-helibase": "/icons/firemap-symbol-helibase.png",
  "symbol-helipad": "/icons/unconfirmed-firemap-symbol-helipad.png",
  "confirmed-symbol-helipad": "/icons/firemap-symbol-helipad.png",
  "firemap-symbol-hydrant-point": "/icons/firemap-symbol-hydrant-point.png",
  "firemap-symbol-livestock": "/icons/firemap-symbol-livestock.png",
  "firemap-symbol-medical": "/icons/firemap-symbol-medical.png",
  "firemap-symbol-mobile-weather-station":
    "/icons/firemap-symbol-mobile-weather-station.png",
  "firemap-symbol-plant": "/icons/firemap-symbol-plant.png",
  "firemap-symbol-police-vehicle": "/icons/firemap-symbol-police-vehicle.png",
  "symbol-property-damaged":
    "/icons/unconfirmed-firemap-symbol-property-damaged.png",
  "confirmed-symbol-property-damaged":
    "/icons/firemap-symbol-property-damaged.png",
  "firemap-symbol-raft-crew": "/icons/firemap-symbol-raft-crew.png",
  "symbol-refuge-area": "/icons/unconfirmed-firemap-symbol-refuge-area.png",
  "confirmed-symbol-refuge-area": "/icons/firemap-symbol-refuge-area.png",
  "symbol-road-closure": "/icons/unconfirmed-firemap-symbol-road-closure.png",
  "confirmed-symbol-road-closure": "/icons/firemap-symbol-road-closure.png",
  "firemap-symbol-sector-command-point":
    "/icons/firemap-symbol-sector-command-point.png",
  "firemap-symbol-ses-vehicle": "/icons/firemap-symbol-ses-vehicle.png",
  "firemap-symbol-smoker-on-ground":
    "/icons/firemap-symbol-smoker-on-ground.png",
  "firemap-symbol-smoker-standing": "/icons/firemap-symbol-smoker-standing.png",
  "symbol-staging-area": "/icons/unconfirmed-firemap-symbol-staging-area.png",
  "confirmed-symbol-staging-area": "/icons/firemap-symbol-staging-area.png",
  "symbol-tree-down": "/icons/unconfirmed-firemap-symbol-tree-down.png",
  "confirmed-symbol-tree-down": "/icons/firemap-symbol-tree-down.png",
  "firemap-symbol-water-point": "/icons/firemap-symbol-water-point.png",
  "firemap-symbol-water-point-vehicle":
    "/icons/firemap-symbol-water-point-vehicle.png",
  "firemap-symbol-water-point-helicopter":
    "/icons/firemap-symbol-water-point-helicopter.png",
  "firemap-symbol-divisional-boundary":
    "/icons/firemap-symbol-divisional-boundary.png",
  "firemap-symbol-sector-boundary": "/icons/firemap-symbol-sector-boundary.png",
  "firemap-symbol-aerial-ignition": "/icons/firemap-symbol-aerial-ignition.png",
  "firemap-symbol-spot-fire": "/icons/firemap-symbol-spot-fire.png",
  "firemap-symbol-wind-direction": "/icons/firemap-symbol-wind-direction.png",
  "firemap-symbol-fire-direction": "/icons/firemap-symbol-fire-direction.png",
  "firemap-symbol-fire-origin": "/icons/firemap-symbol-fire-origin-point.png",
};
