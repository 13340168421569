import { Alert } from "@app/design-system";
import { useGetAnnotationsId } from "../../../../.rest-hooks/annotations";
import type { UploadedMediaInteractionState } from "../../map/IncidentAnnotations/UploadedMedia/interactions";
import { isMediaAnnotation } from "../../map/IncidentAnnotations/UploadedMedia/types";
import MapPopupView from "../SpatialPopup/MapPopupView";
import SpatialPopup from "../SpatialPopup/SpatialPopup";
import SkeletonUploadedMediaHoverPopupView from "./SkeletonUploadedMediaHoverPopupView";
import UploadedMediaHoverPopupView from "./UploadedMediaHoverPopupView";

interface UploadedMediaHoverPopupProps {
  onClose: () => void;
  state: UploadedMediaInteractionState;
}

const UploadedMediaHoverPopup = ({
  onClose,
  state,
}: UploadedMediaHoverPopupProps) => {
  const annotationId = state.properties?.data.annotationId ?? "";

  const {
    data: annotationData,
    isError,
    isPending,
  } = useGetAnnotationsId(annotationId);

  let content: React.ReactNode;

  if (isError) {
    content = (
      <MapPopupView header="Media annotation" onClose={onClose} hasClose>
        <Alert variant="error" title="Unable to load annotation" />
      </MapPopupView>
    );
  } else if (isPending) {
    content = <SkeletonUploadedMediaHoverPopupView onClose={onClose} />;
  } else {
    const annotation = annotationData.data.data;

    if (!isMediaAnnotation(annotation)) {
      content = (
        <MapPopupView header="Media annotation" onClose={onClose} hasClose>
          <Alert variant="error" title="Invalid annotation" />
        </MapPopupView>
      );
    } else {
      content = (
        <UploadedMediaHoverPopupView
          annotation={annotation}
          onClose={onClose}
        />
      );
    }
  }

  return (
    <SpatialPopup
      isOpen={state.isActive}
      onClose={onClose}
      popupConfig={{
        id: state.id,
        offset: 16,
        size: "lg",
        type: "hover",
        variant: "inverse",
      }}
      lngLat={state.properties?.lngLat}
    >
      {content}
    </SpatialPopup>
  );
};

export default UploadedMediaHoverPopup;
