import Skeleton from "react-loading-skeleton";
import MapPopupDialog, {
  StyledSkeletonMapPopupDialogButton,
} from "../SpatialPopup/MapPopupDialog";
import { StyledText } from "./AnnotationPopupDialog";

interface SkeletonAnnotationPopupDialogProps {
  onClose: () => void;
}

const SkeletonAnnotationPopupDialog = ({
  onClose,
}: SkeletonAnnotationPopupDialogProps) => {
  return (
    <MapPopupDialog
      footer={<StyledSkeletonMapPopupDialogButton width="80px" />}
      onClose={onClose}
      title={<Skeleton />}
    >
      <StyledText>
        <Skeleton />
      </StyledText>
      <StyledText>
        <Skeleton />
      </StyledText>
      <StyledText>
        <Skeleton />
      </StyledText>
    </MapPopupDialog>
  );
};

export default SkeletonAnnotationPopupDialog;
