import { ModalForm, onPromise, showToast } from "@app/design-system";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";
import {
  getGetAnnotationsIdQueryKey,
  getGetIncidentsIdAnnotationsQueryKey,
  usePatchAnnotationsId,
} from "../../../../.rest-hooks/annotations";
import type { MediaAnnotation } from "../../map/IncidentAnnotations/UploadedMedia/types";
import EditUploadedMediaForm, {
  type EditMediaFormValues,
  getDefaultEditMediaFormValues,
} from "./EditUploadedMediaForm";

interface EditMediaAnnotationModalProps {
  annotation: MediaAnnotation;
  incidentId: string;
  isOpen: boolean;
  onClose: () => void;
}

const EditMediaAnnotationModal = ({
  annotation,
  incidentId,
  isOpen,
  onClose,
}: EditMediaAnnotationModalProps) => {
  const queryClient = useQueryClient();

  const { mutateAsync: editMediaAnnotationMutation } = usePatchAnnotationsId({
    mutation: {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: getGetAnnotationsIdQueryKey(annotation.id),
        });
        void queryClient.invalidateQueries({
          queryKey: getGetIncidentsIdAnnotationsQueryKey(incidentId),
        });

        onClose();
        showToast({
          variant: "success",
          title: "Markup updated",
        });
      },
      onError: () => {
        showToast({
          variant: "error",
          title: "Unable to update markup",
        });
      },
    },
  });

  const form = useForm<EditMediaFormValues>({
    defaultValues: getDefaultEditMediaFormValues(annotation),
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = form;

  useEffect(() => {
    reset(getDefaultEditMediaFormValues(annotation));
  }, [annotation, isOpen, reset]);

  const onEditSubmit: SubmitHandler<EditMediaFormValues> = (values) => {
    return editMediaAnnotationMutation({
      id: annotation.id,
      data: {
        confirmed: values.confirmed,
        location: values.location,
        note: values.note,
      },
    });
  };

  return (
    <ModalForm
      id="editMediaAnnotation"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onCancel={onClose}
      onSubmit={onPromise(handleSubmit(onEditSubmit))}
      submitLabel="Save"
      title="Edit Uploaded Media"
    >
      <FormProvider {...form}>
        <EditUploadedMediaForm />
      </FormProvider>
    </ModalForm>
  );
};

export default EditMediaAnnotationModal;
