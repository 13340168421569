import type { Position } from "geojson";
import { useEffect, useState } from "react";
import useEscapeKeyDown from "../../../../hooks/useEscapeKeyDown";
import { useVisualiser } from "../../../ui/Visualiser/VisualiserProvider";
import useMapContext from "../../Map/useMapContext";
import type { MapboxMouseEvent } from "../../types";
import SymbolAnnotationModal from "./SymbolAnnotationModal";

interface SymbolAnnotationLayerProps {
  incidentId: string;
  symbolName: string;
}

const SymbolAnnotationLayer = ({
  incidentId,
  symbolName,
}: SymbolAnnotationLayerProps) => {
  const { onToolComplete } = useVisualiser();
  const map = useMapContext();

  const [position, setPosition] = useState<Position | null>(null);

  useEffect(() => {
    map.getCanvas().style.cursor = "crosshair";

    const onClick = (event: MapboxMouseEvent) => {
      const { lng, lat } = event.lngLat.wrap();
      setPosition([lng, lat]);
    };

    map.on("click", onClick);

    return () => {
      map.getCanvas().style.cursor = "";
      map.off("click", onClick);
    };
  }, [map, position]);

  const isSymbolAnnotationModalOpen = !!position;

  const onEscapeKeyDown = () => {
    if (isSymbolAnnotationModalOpen) return;

    onToolComplete();
  };

  const onClose = () => {
    onToolComplete();
  };

  useEscapeKeyDown(onEscapeKeyDown);

  return (
    <SymbolAnnotationModal
      incidentId={incidentId}
      isOpen={isSymbolAnnotationModalOpen}
      onClose={onClose}
      position={position}
      symbolName={symbolName}
    />
  );
};

export default SymbolAnnotationLayer;
