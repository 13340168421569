import { Field, TextArea } from "@app/design-system";
import { useFormContext } from "react-hook-form";

export interface LineAnnotationFormValues {
  note: string;
}

export const defaultLineAnnotationFormValues: LineAnnotationFormValues = {
  note: "",
};

export const getDefaultLineAnnotationFormValues =
  (): LineAnnotationFormValues => {
    return {
      note: "",
    };
  };

const LineAnnotationForm = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<LineAnnotationFormValues>();

  return (
    <Field error={errors.note} htmlFor="note" label="Note or label">
      <TextArea
        autoFocus
        id="note"
        placeholder="Information about your note or label"
        maxLength={300}
        {...register("note", { required: "Please enter a note" })}
      />
    </Field>
  );
};

export default LineAnnotationForm;
