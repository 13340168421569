import AnnotatedTextHoverPopup from "../../../popup/AnnotatedTextPopup/AnnotatedTextHoverPopup";
import AnnotatedTextPopup from "../../../popup/AnnotatedTextPopup/AnnotatedTextPopup";
import useLayerInteractions from "../../MapInteractions/useLayerInteractions";
import { ANNOTATED_TEXT_LAYER_ID } from "./constants";
import { getPropertiesFromFeature } from "./interactions";
import type { TextAnnotation } from "./types";
import useAnnotatedTextMapData from "./useAnnotatedTextMapData";
import useAnnotatedTextMapLayers from "./useAnnotatedTextMapLayers";

interface AnnotatedTextLayerProps {
  annotations: TextAnnotation[];
  incidentId: string;
}

const AnnotatedTextLayer = ({
  annotations,
  incidentId,
}: AnnotatedTextLayerProps) => {
  useAnnotatedTextMapLayers();
  useAnnotatedTextMapData({ annotations });

  const {
    deactivateClickState,
    deactivateHoverState,
    clickedState,
    hoveredState,
  } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: ANNOTATED_TEXT_LAYER_ID,
  });

  return (
    <>
      <AnnotatedTextHoverPopup
        onClose={deactivateHoverState}
        state={hoveredState}
      />
      <AnnotatedTextPopup
        incidentId={incidentId}
        onClose={deactivateClickState}
        state={clickedState}
      />
    </>
  );
};

export default AnnotatedTextLayer;
