import React from "react";
import DrawerContent from "../../../drawers/DrawerOverlay/DrawerContent";
import MarkupToolsetsList from "../../../drawers/LayersDrawerCell/MarkupToolsetsList";

const MarkupDrawerView = () => {
  return (
    <DrawerContent>
      <MarkupToolsetsList data-testid="markup-toolsets-list" />
    </DrawerContent>
  );
};

export default MarkupDrawerView;
