import { Field, TextArea } from "@app/design-system";
import { useFormContext } from "react-hook-form";

export interface PolygonAnnotationFormValues {
  note: string;
  patternId: string;
}

export const defaultPolygonAnnotationFormValues: PolygonAnnotationFormValues = {
  note: "",
  patternId: "",
};

export const getDefaultPolygonAnnotationFormValues =
  (): PolygonAnnotationFormValues => {
    return {
      note: "",
      patternId: "",
    };
  };

const PolygonAnnotationForm = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<PolygonAnnotationFormValues>();

  return (
    <Field error={errors.note} htmlFor="note" label="Note">
      <TextArea
        autoFocus
        id="note"
        placeholder="Information about your note"
        maxLength={300}
        {...register("note", { required: "Please enter a note" })}
      />
    </Field>
  );
};

export default PolygonAnnotationForm;
