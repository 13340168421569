import AnnotatedPolygonHoverPopup from "../../../popup/AnnotatedPolygonPopup/AnnotatedPolygonHoverPopup";
import AnnotatedPolygonPopup from "../../../popup/AnnotatedPolygonPopup/AnnotatedPolygonPopup";
import useLayerInteractions from "../../MapInteractions/useLayerInteractions";
import { ANNOTATED_POLYGON_LAYER_ID } from "./constants";
import { getPropertiesFromFeature } from "./interactions";
import type { PolygonAnnotation } from "./types";
import useAnnotatedPolygonMapData from "./useAnnotatedPolygonMapData";
import useAnnotatedPolygonMapLayers from "./useAnnotatedPolygonMapLayers";

interface AnnotatedPolygonLayerProps {
  annotations: PolygonAnnotation[];
  incidentId: string;
}

const AnnotatedPolygonLayer = ({
  annotations,
  incidentId,
}: AnnotatedPolygonLayerProps) => {
  useAnnotatedPolygonMapLayers();
  useAnnotatedPolygonMapData({ annotations });

  const {
    deactivateClickState,
    deactivateHoverState,
    clickedState,
    hoveredState,
  } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: ANNOTATED_POLYGON_LAYER_ID,
  });

  return (
    <>
      <AnnotatedPolygonHoverPopup
        onClose={deactivateHoverState}
        state={hoveredState}
      />
      <AnnotatedPolygonPopup
        incidentId={incidentId}
        onClose={deactivateClickState}
        state={clickedState}
      />
    </>
  );
};

export default AnnotatedPolygonLayer;
