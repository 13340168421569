import { textAnnotation } from "../../../../config/tools";
import { useVisualiser } from "../../../ui/Visualiser/VisualiserProvider";
import TextAnnotationLayer from "./TextAnnotationLayer";

interface TextAnnotationProps {
  incidentId: string;
}

const TextAnnotation = ({ incidentId }: TextAnnotationProps) => {
  const { isToolActive } = useVisualiser();

  if (!isToolActive(textAnnotation)) {
    return null;
  }

  return <TextAnnotationLayer incidentId={incidentId} />;
};

export default TextAnnotation;
